import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';
import { IAccommodationProduct, IHotel, ISeason } from 'services/BackendApi';
import { Season } from './Season';
import { Room } from './Room';
import { produce } from 'immer';
import * as _ from 'lodash-es';

export const CreateRoomModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const [draftRoom, setDraftRoom] = React.useState<Partial<IAccommodationProduct>>({
    meta: {
      description: '',
      size: 0,
      categoryType: '',
      amenities: [],
      moreInformation: '',
    },
    options: {
      occupancy: {
        standardOccupancy: 0,
        maximumPeople: 0,
        limits: [],
      },
      ages: [],
    },
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Room</h1>
      <Room
        room={draftRoom}
        onUpdate={(field, value) => {
          const newRoom = produce(draftRoom, _draftRoom => {
            _.set(_draftRoom, field, value);
          });
          setDraftRoom({
            ...newRoom,
          });
        }}
        onCta={() => {
          onConfirm(draftRoom);
        }}
        ctaLabel="Create Room"
      />
    </GeneralModal>
  );
};
