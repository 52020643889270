import React, { useCallback } from 'react';
import CopyIcon from 'ui/Icons/copy.component.svg';
import { enqueueNotification } from 'store/modules/ui';
import {
  AppliedSuplement,
  BookingBuilder,
  Fine,
  GroundService,
  IHotel,
  ProductSetAccommodation,
  Transfer,
} from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import { formatDateDisplay, formatPrice, formatPriceCents, getCurrencySymbol, numberOfNights, offsetDate } from 'utils';
import * as HotelAccommodationProductSelectors from 'store/modules/hotelAccommodationProducts/selectors';
import { pluralize } from 'utils/string';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import {
  composeCancellationPolicyFromRoomExpenseInfo,
  extractCancellationPolicies,
} from 'common-lib/cancellation-policy-composer';
import { IHAPFormattedAccommodationProduct } from 'store/modules/hotelAccommodationProducts/types';
import { SvgIcon } from 'ui/SvgIcon';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { convertBasketBuildToBookingBuilderFormat } from '../utils';
import { isNil } from 'lodash-es';
import { MenuButton } from 'ui/MenuButton/stateful';
import { isRefundable as isRefundableFn } from 'common-lib/cancellation-policy-composer';


const getMealPlan = (accommodation: ProductSetAccommodation) => {
  return accommodation.availableSubProductSets['Meal Plan'].find(item => item.selected);
};

const getExtraPersonSupplement = (accommodation: ProductSetAccommodation) => {
  return accommodation.availableSubProductSets['Supplement'].find(item => item.selected);
};

const getHotelAndRegion = (hotel: IHotel) => {
  const lines: (string | null)[] = [hotel.name];
  if (hotel.region) {
    const hotelRegion = hotel.region ? '(' + hotel.region?.toUpperCase() + ')' : null;
    lines.push(hotelRegion);
  }
  return lines.filter(Boolean).join(' ');
};

const getDates = (checkInDate: string, checkOutDate: string) => {
  return `${checkInDate ? formatDateDisplay(checkInDate) : ''} - ${
    checkOutDate ? formatDateDisplay(checkOutDate) : ''
  } (${numberOfNights(checkInDate, checkOutDate)} ${pluralize(numberOfNights(checkInDate, checkOutDate), 'night')})`;
};

const getRateType = (booking: BookingBuilder) => {
  return booking.response.potentialBooking.Accommodation[0].isLiveRate ? 'LIVE RATE' : 'STATIC RATE';
};

const getTotal = (
  currencySymbol: string, 
  product: any, 
  withCommission: boolean, 
  commissionPercentage: number
) => {
  if (product?.isOnRequestOrPartiallyOnRequest) {
    return 'On Request';
  }
  return product?.total ? `${currencySymbol} ${formatPrice(withCommission ? product.total * (1 + commissionPercentage / 100) : product.total)}` : '';
};

const getCancellationPolicies = (currencySymbol: string, booking: BookingBuilder, withCommission: boolean, commissionPercentage: number) => {
  const cancellationPolicyLines: string[] = [];
  booking.response.expenseInfosGroupedByRoom.forEach((item, index) => {
    if(withCommission){
      item.expenseDeadlines = [...item.expenseDeadlines.map( expenseDeadLine => ({
        ...expenseDeadLine, 
        amount: expenseDeadLine.amount * (1 + commissionPercentage / 100), 
      }))]
    }
    const accommodation = [
      `- Accommodation ${index + 1}`,
      composeCancellationPolicyFromRoomExpenseInfo(item, {
        currencySymbol: currencySymbol,
        appendLines: [' (at 00.00 time at destination)'],
      }).join('. '),
    ].join(': ');
    cancellationPolicyLines.push(accommodation);
  });

  extractCancellationPolicies(booking.response.potentialBooking.Fine).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy);
  });
  extractCancellationPolicies(booking.response.potentialBooking['Ground Service']).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy);
  });
  extractCancellationPolicies(booking.response.potentialBooking.Supplement).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy);
  });
  extractCancellationPolicies(booking.response.potentialBooking.Transfer).forEach(cancellationPolicy => {
    cancellationPolicyLines.push('- ' + cancellationPolicy.replace(/\n/g, ' '));
  });

  return cancellationPolicyLines;
};

const getCost = (
  booking: BookingBuilder, 
  currencySymbol: string, 
  withCommission: boolean, 
  commissionPercentage: number
) => {
  if (booking.response.totals.oneOrMoreItemsOnRequest) {
    return `On Request ${getRateType(booking)}`;
  }
  return `*${currencySymbol} ${formatPriceCents(
    withCommission ? booking.response.totals.totalForPricedItemsCents * (1 + commissionPercentage / 100) : booking.response.totals.totalForPricedItemsCents
  )}* (Before ${currencySymbol} ${formatPriceCents(
    withCommission ? booking.response.totals.totalBeforeDiscountForPricedItemsCents * (1 + commissionPercentage / 100) : booking.response.totals.totalBeforeDiscountForPricedItemsCents
  )}) ${getRateType(booking)}`;
};

const generateOtherItems = (
  currencySymbol: string,
  otherItems: Transfer[] | AppliedSuplement[] | GroundService[] | Fine[],
  isFull: boolean = true,
  withCommission: boolean, 
  commissionPercentage: number
) => {
  return otherItems
    .filter(item => item.selected)
    .map(transfer => `- ${transfer.products[0].name || ''}${isFull ? ` - ${getTotal(currencySymbol, transfer, withCommission, commissionPercentage)}` : ''}`)
    .join('\n');
};

const getRefundableText = (isRefundable: boolean | null | undefined) => {
  if (isRefundable === true) {
    return '- Refundable';
  }
  if (isRefundable === false) {
    return '- Not Refundable';
  }
  return '';
};

const getBookingInfo = (booking: BookingBuilder, isFull: boolean) => {
  const numberOfRooms = booking.response.availableProductSets.Accommodation?.length ?? 0;
  const numberOfGuests =
    booking.request.guestAges.numberOfAdults + (booking.request.guestAges.agesOfAllChildren?.length ?? 0);
  const numberOfTransfers = booking.response.availableProductSets.Transfer?.filter(item => item.selected).length ?? 0;
  const numberOfGroundServices =
    booking.response.availableProductSets['Ground Service']?.filter(item => item.selected).length ?? 0;
  const numberOfOtherItems =
    (booking.response.availableProductSets.Supplement?.filter(item => item.selected).length ?? 0) +
    (booking.response.availableProductSets.Fine?.filter(item => item.selected).length ?? 0);

  let quantity = [
    { quantity: numberOfGuests, text: `${numberOfGuests} ${pluralize(numberOfGuests, 'Guest')}` },
    { quantity: numberOfRooms, text: `${numberOfRooms} ${pluralize(numberOfRooms, 'Room')}` },
  ];
  if (isFull) {
    quantity = [ 
      ...quantity,
      { quantity: numberOfTransfers, text: `${numberOfTransfers} ${pluralize(numberOfTransfers, 'Transfer')}` },
      {
        quantity: numberOfGroundServices,
        text: `${numberOfGroundServices} ${pluralize(numberOfGroundServices, 'Ground Service')}`,
      },
      { quantity: numberOfOtherItems, text: `${numberOfOtherItems} ${pluralize(numberOfOtherItems, 'Other Item')}` },
    ]
  }
  return quantity
    .filter(item => item.quantity > 0)
    .map(item => item.text)
    .join(' | ');
};

export const getBookingConfirmationClipboardFormat = (
  booking: BookingBuilder,
  paymentTerms: string[],
  formattedAccommodationProducts: IHAPFormattedAccommodationProduct[],
  { isFull, withCommission, commissionPercentage } : { isFull : boolean, withCommission : boolean, commissionPercentage: number},
) => {
  const hotel = booking.response.hotel;
  const currencySymbol = getCurrencySymbol(booking.response.currency);
  const checkInDate = booking.request.startDate;
  const checkOutDate = format(addDays(offsetDate(booking.request.endDate), 1), 'yyyy-MM-dd');

  return `*${getHotelAndRegion(hotel)}*
${getDates(checkInDate, checkOutDate)} ${getBookingInfo(booking, isFull)}
Total cost: ${getCost(booking, currencySymbol, withCommission, commissionPercentage)}

${booking.response.availableProductSets.Accommodation.map((accommodation, accommodationIndex) => {
  const requestedAccommodation = booking.request.Accommodation[accommodationIndex];
  // Use the same criteria as we are using for the expandable info
  const isRefundable = isRefundableFn(booking.response.expenseInfosGroupedByRoom[accommodationIndex]);
  const mealPlan = getMealPlan(accommodation);
  const supplement = getExtraPersonSupplement(accommodation);
  const { numberOfAdults, agesOfAllChildren = [] } = requestedAccommodation.guestAges;
  // Text parts
  const nameAndDatesStr = `- 1 x ${accommodation.products[0].name || ''} - ${getDates(
    requestedAccommodation.startDate,
    format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd')
  )} `;
  const guestsStr = `- ${numberOfAdults} x Adult ${agesOfAllChildren.length > 0 ? `+ ${agesOfAllChildren.length} x Children (${agesOfAllChildren.map(age => age).join(',')} yo) ` : ''}`;
  const costsStr = `- ${getTotal(currencySymbol, accommodation, withCommission, commissionPercentage)}`;
  const mealPlanStr = `${!isNil(mealPlan?.products) ? `${
    mealPlan?.products.map(mealPlanProduct => mealPlanProduct.name
    ).join(', ')
  }${isFull ? ` - ${getTotal(currencySymbol, mealPlan, withCommission, commissionPercentage)}` : ''}` : ''}\n`;
  const refundableStr = `${getRefundableText(isRefundable)}\n`;
  const supplementStr = `${!isNil(supplement?.products) ? `${
    supplement?.products.map(supplementProduct => supplementProduct.name
    ).join(', ')
  }${isFull ? ` - ${getTotal(currencySymbol, supplement, withCommission, commissionPercentage)}` : ''}` : ''}\n`;
  return `${nameAndDatesStr}${guestsStr}${isFull ? costsStr : ''} ${refundableStr}${mealPlanStr}${supplementStr}`;
}).join('\n')}${[
  generateOtherItems(currencySymbol, booking.response.availableProductSets.Transfer, isFull, withCommission, commissionPercentage),
  generateOtherItems(currencySymbol, booking.response.availableProductSets['Ground Service'], isFull, withCommission, commissionPercentage),
  generateOtherItems(currencySymbol, booking.response.availableProductSets.Supplement, isFull, withCommission, commissionPercentage),
  generateOtherItems(currencySymbol, booking.response.availableProductSets.Fine, isFull, withCommission, commissionPercentage),
]
  .filter(Boolean)
  .join('\n')}
${isFull ? 
  `\n${paymentTerms.length > 0 ? `${['*Payment Terms*', ...paymentTerms.map(item => '- ' + item)].join('\n')}\n` : ''}${`*Cancellation Policy*\n${getCancellationPolicies(currencySymbol, booking, withCommission, commissionPercentage).join('\n')}`}\n` 
: ''}`
};

const buildToBooking = (build : IBasketBuildL4) : BookingBuilder => {
  const hasLatestBookingBuild = !isNil(build.latestBookingBuilderResponse);
  // define the 2 booking builders (latest and initial)
  const latestBookingBuilder: BookingBuilder | undefined = hasLatestBookingBuild
    ? convertBasketBuildToBookingBuilderFormat(build, true)
    : undefined;
  const initialBookingBuilder = convertBasketBuildToBookingBuilderFormat(build, false);

  // determine which one we're going to use for rendering everything
  const effectiveBookingBuilder =
    hasLatestBookingBuild && latestBookingBuilder?.response.canBeBooked === true
      ? latestBookingBuilder
      : initialBookingBuilder;

  return effectiveBookingBuilder;
}

interface ICopyButtonMultipleProps {
  builds: IBasketBuildL4[];
}

export const CopyButtonMultiple: React.FC<ICopyButtonMultipleProps> = React.memo(({ builds }) => {
  const dispatch = useDispatch();
  const paymentTerms = useSelector(BookingBuilderSelectors.bookingPaymentTermsSelector);
  const formattedAccommodationProducts: IHAPFormattedAccommodationProduct[] = useSelector(
    HotelAccommodationProductSelectors.getHotelAccommodationProductsSelector
  );
  const handleCopy = useCallback(async (mode: string) => {
    try {
      const clipboardContent = builds.map(
        build => {
          const booking = buildToBooking(build);
          const totalAmount : number = parseFloat(booking.response?.totals.total || '0');
          const taMarginAmount = parseFloat(build.taMarginAmount || '0');
          const commissionPercentage = (taMarginAmount/totalAmount) * 100;
          return (
            getBookingConfirmationClipboardFormat(
              booking, 
              paymentTerms, 
              formattedAccommodationProducts,
              { 
                isFull: ['full-without-commission', 'full-with-commission'].includes(mode),
                withCommission: ['summary-with-commission', 'full-with-commission'].includes(mode),
                commissionPercentage,
              }
            )
          );
        }
      ).join('\n------------------\n\n');
      await navigator.clipboard.writeText(clipboardContent);
      dispatch(
        enqueueNotification({
          message: `Copied booking information to clipboard`,
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      console.log('e', e);
      dispatch(
        enqueueNotification({
          message: `Failed to copy booking information to clipboard`,
          options: { variant: 'error' },
        })
      );
    }
  }, [builds, dispatch, paymentTerms]);

  return (
    <MenuButton
      className={`${`copy-all-builds flex`} rounded-none ${builds.length === 0 ? 'text-brown-20 border-brown-20' : 'text-brown-80 border-brown-80'}`}
      innerClassName={'font-light'}
      fixedWidth="150px"
      options={[
        {
          key: 'summary-without-commission',
          label: 'Summary (Travel Partner)',
          handler: () => handleCopy('summary-without-commission'),
        },
        {
          key: 'summary-with-commission',
          label: 'Summary (Final Client)',
          handler: () => handleCopy('summary-with-commission'),
        },
        {
          key: 'full-without-commission',
          label: 'Full (Travel Partner)',
          handler: () => handleCopy('full-without-commission'),
        },
        {
          key: 'full-with-commission',
          label: 'Full (Final Client)',
          handler: () => handleCopy('full-with-commission'),
        }
      ]}
      onSelect={(option) => option.handler()}
      disabled={builds.length === 0}
      rounded={false}
    >
      <i className="far ml-2 mr-2">
        <SvgIcon IconComponent={CopyIcon} width="18px" height="18px" className={`${builds.length === 0 ? 'fill-brown-20' : 'fill-brown-80'}`} />
      </i>
      <span>{`COPY (${builds.length})`}</span>
    </MenuButton>
  );
});
