import React, { useEffect } from 'react';
import classNames from 'classnames';
import { IMultiselectProps, Multiselect } from 'ui/Multiselect';
import UiCheckbox from 'ui/Checkbox';
import { Radio } from 'ui/Radio';
import { useDebounce } from 'hooks/useDebounce';
import FluidButton from 'ui/FluidButton';
import { IAgeName, IOccupancyLimit } from 'services/BackendApi';
import { produce } from 'immer';
import { TCountryCode } from 'interfaces';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';

export const markupTypes = [
  { value: 'flat', label: 'Flat' },
  { value: 'percentage', label: 'Percentage' },
];

export const categoryTypes = [
  { value: 'perAccommodationProduct', label: 'Per accomodation' },
  { value: 'perBooking', label: 'Per booking' },
  { value: 'perNight', label: 'Per night' },
  { value: 'perPerson', label: 'Per person' },
  { value: 'perPersonPerNight', label: 'Per person per night' },
];

export const Input = ({
  label,
  value,
  onChange,
  onDebounceChange,
  className,
  labelClassName,
  inputClassName,
}: {
  label?: string | React.ReactNode;
  value: string;
  onChange: (newVal: string) => void;
  onDebounceChange?: (newVal: string) => void;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
}) => {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebounce(value, 1000);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);
  useEffect(() => {
    // This effect will only run when debouncedSearchTerm changes
    if (onDebounceChange) {
      onDebounceChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      {label && <span className={classNames('text-sm', labelClassName)}>{label}</span>}
      <input
        value={searchTerm}
        className={classNames(
          'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans min-h-37px max-h-37px',
          inputClassName
        )}
        type="text"
        onChange={e => {
          setSearchTerm(e.target.value);
          onChange(e.target.value);
        }}
      />
    </label>
  );
};

export const Dropdown = ({
  label,
  values,
  options,
  onChange,
  className,
  labelClassName,
  dropdownClassName,
  multiselectProps,
}: {
  label: string | React.ReactNode;
  values: string[];
  options: IMultiselectProps['options'];
  onChange: (selectedValues: string[]) => void;
  multiselectProps?: Partial<IMultiselectProps>;
  className?: string;
  labelClassName?: string;
  dropdownClassName?: string;
}) => {
  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      <span className={classNames('text-sm', labelClassName)}>{label}</span>
      <Multiselect
        className={classNames('bg-white', dropdownClassName)}
        itemsClassname="bg-white"
        itemCtaClassName="hover:bg-gray-10"
        onUpdate={sv => {
          onChange(sv);
        }}
        options={options}
        selectedValues={values}
        {...multiselectProps}
      />
    </label>
  );
};

export const TextArea = ({
  label,
  value,
  onChange,
  rows = 3,
}: {
  label: string | React.ReactNode;
  value: string;
  onChange: (newVal: string) => void;
  rows?: number;
}) => {
  return (
    <label className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <textarea
        value={value}
        className={classNames(
          'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans'
        )}
        rows={rows}
        onChange={e => onChange(e.target.value)}
      />
    </label>
  );
};

export const Checkbox = ({
  label,
  value,
  onChange,
  className,
}: {
  label: string | React.ReactNode;
  value: boolean;
  onChange: (newVal: boolean) => void;
  className?: string;
}) => {
  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      <span className="text-sm">{label}</span>
      <UiCheckbox
        checked={value}
        onChange={e => {
          console.log('e.currentTarget.checked', e.currentTarget.checked);
          onChange(e.currentTarget.checked);
        }}
      />
    </label>
  );
};

export const CheckboxCollection = ({
  label,
  collection,
  onChange,
}: {
  label: string | React.ReactNode;
  collection: any[];
  onChange: any;
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <div className="flex flex-wrap items-center gap-4">
        {collection.map(item => {
          return (
            <label key={item.label} className="flex flex-row items-center gap-2">
              <UiCheckbox
                checked={item.isChecked}
                onChange={e => {
                  onChange(item.label, e.currentTarget.checked);
                }}
              />
              <span>{item.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export const RadioCollection = ({
  label,
  onChange,
  collection,
  disabled,
}: {
  label?: string | React.ReactNode;
  onChange: (newCheckedVal: string | null) => void;
  collection: {
    isChecked: boolean;
    value: string | null;
    label: string;
  }[];
  disabled?: boolean;
}) => {
  return (
    <div className={classNames('flex flex-col space-y-1', { 'opacity-50 pointer-events-none': disabled })}>
      {label && <span className="text-sm">{label}</span>}
      <div className="flex flex-col space-y-1">
        {collection.map((item, index) => (
          <label className="block" key={item.label}>
            <Radio checked={item.isChecked} onClick={() => onChange(item.value)} />
            <span className="ml-2">{item.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export const CrudList = ({
  label,
  collection,
  className,
  labelClassName,
  inputClassName,
  onChange,
}: {
  label?: string | React.ReactNode;
  collection: string[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (newCollection: any[]) => void;
}) => {
  return (
    <fieldset className={classNames('p-0 m-0 flex flex-col space-y-4 relative w-full', className)}>
      {label && <legend className={classNames('text-sm', labelClassName)}>{label}</legend>}
      <div className="grid grid-cols-3 gap-4">
        {collection.map((item, index) => {
          return (
            <div key={index} className="flex flex-row space-x-2">
              <input
                className={classNames(
                  'focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans min-h-37px max-h-37px w-full',
                  inputClassName
                )}
                type="text"
                value={item}
                onChange={e => onChange(collection.map((i, iIndex) => (iIndex === index ? e.target.value : i)))}
              />
              <button onClick={() => onChange(collection.filter(i => i !== item))}>X</button>
            </div>
          );
        })}
      </div>
      <FluidButton type="secondary" className="w-[100px]" onClick={() => onChange([...collection, ''])}>
        Add
      </FluidButton>
    </fieldset>
  );
};

export const AgeGroups = ({
  label,
  collection,
  className,
  labelClassName,
  inputClassName,
  onChange,
}: {
  label?: string | React.ReactNode;
  collection: IAgeName[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (newCollection: any[]) => void;
}) => {
  return (
    <fieldset className={'border border-solid border-gray-40 p-2 flex flex-col space-y-2'}>
      <legend className={labelClassName}>{label}</legend>

      {collection.map((ageGroup, ageGroupIndex) => {
        return (
          <div key={ageGroupIndex} className="flex flex-row space-x-2 items-center">
            <Input
              label={'Name'}
              value={ageGroup.name}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].name = newVal;
                });
                onChange(newAges);
              }}
            />
            <Input
              label={'From'}
              value={ageGroup.ageFrom?.toString() || ''}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].ageFrom = parseInt(newVal);
                });
                onChange(newAges);
              }}
            />
            <Input
              label={'To'}
              value={ageGroup.ageTo?.toString() || ''}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].ageTo = parseInt(newVal);
                });
                onChange(newAges);
              }}
            />

            <button
              onClick={() => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection.splice(ageGroupIndex, 1);
                });
                onChange(newAges);
              }}
              className="bg-gray-100"
            >
              X
            </button>
          </div>
        );
      })}

      <FluidButton
        type="secondary"
        className="w-[200px] self-start"
        onClick={() => {
          const newAges = produce(collection, draftCollection => {
            draftCollection.push({
              name: '',
              ageFrom: 0,
              ageTo: 0,
            });
          });
          onChange(newAges);
        }}
      >
        Add Age Group
      </FluidButton>
    </fieldset>
  );
};

export const OccupancyLimits = ({
  label,
  collection,
  className,
  labelClassName,
  inputClassName,
  onChange,
}: {
  label?: string | React.ReactNode;
  collection: IOccupancyLimit[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (newCollection: any[]) => void;
}) => {
  return (
    <fieldset className={'border border-solid border-gray-40 p-2 flex flex-col space-y-2'}>
      <legend className={labelClassName}>{label}</legend>

      {collection.map((ageGroup, ageGroupIndex) => {
        return (
          <div key={ageGroupIndex} className="flex flex-row space-x-2 items-center">
            <Input
              label={'Name'}
              value={ageGroup.name}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].name = newVal;
                });
                onChange(newAges);
              }}
            />
            <Input
              label={'Minimum'}
              value={ageGroup.minimum?.toString() || ''}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].minimum = parseInt(newVal);
                });
                onChange(newAges);
              }}
            />
            <Input
              label={'Maximum'}
              value={ageGroup.maximum?.toString() || ''}
              onChange={newVal => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection[ageGroupIndex].maximum = parseInt(newVal);
                });
                onChange(newAges);
              }}
            />

            <button
              onClick={() => {
                const newAges = produce(collection, draftCollection => {
                  draftCollection.splice(ageGroupIndex, 1);
                });
                onChange(newAges);
              }}
              className="bg-gray-100"
            >
              X
            </button>
          </div>
        );
      })}

      <FluidButton
        type="secondary"
        className="w-[200px] self-start"
        onClick={() => {
          const newAges = produce(collection, draftCollection => {
            draftCollection.push({
              name: '',
              minimum: 0,
              maximum: 0,
            });
          });
          onChange(newAges);
        }}
      >
        Add Occupancy Limit
      </FluidButton>
    </fieldset>
  );
};

export const CountryList = ({
  label,
  onChange,
  className,
  labelClassName,
  bootstrapCountries,
  selectedCountries,
}: {
  label?: string | React.ReactNode;
  onChange: (newCountryList: IBootstrapCountry[]) => void;
  className?: string;
  labelClassName?: string;
  bootstrapCountries: IBootstrapCountry[];
  selectedCountries: IBootstrapCountry[];
}) => {
  // get the distinct regions from the countries
  const regions = new Set(bootstrapCountries.map(bc => bc.region).filter(Boolean));
  const selectedCountryCodes = selectedCountries.map(c => c.code);
  const mapOfIsSelected = bootstrapCountries.reduce((acc, bc) => {
    acc[bc.code] = selectedCountryCodes.includes(bc.code);
    return acc;
  }, {});

  return (
    <label className={classNames('flex flex-col space-y-1', className)}>
      <span className={classNames('text-sm', labelClassName)}>{label}</span>

      <Checkbox
        label={'All countries'}
        className="o:flex-row o:space-y-0 space-x-2"
        value={selectedCountries.length === bootstrapCountries.length}
        onChange={val => {
          if (val) {
            onChange(bootstrapCountries);
          } else {
            onChange([]);
          }
        }}
      />
      {Array.from(regions).map(region => {
        return (
          <details key={region}>
            <summary>{region}</summary>
            <div className="grid grid-cols-5 gap-2">
              {bootstrapCountries
                .filter(bc => bc.region === region)
                .map(bc => {
                  return (
                    <Checkbox
                      label={bc.name}
                      className="o:flex-row o:space-y-0 space-x-2"
                      key={bc.code}
                      value={mapOfIsSelected[bc.code]}
                      onChange={val => {
                        if (val) {
                          onChange([...selectedCountries, bc]);
                        } else {
                          onChange(selectedCountries.filter(c => c.code !== bc.code));
                        }
                      }}
                    />
                  );
                })}
            </div>
          </details>
        );
      })}
    </label>
  );
};

export const Select = ({
  label,
  value,
  onChange,
  options,
}: {
  label: string | React.ReactNode;
  value: string;
  onChange: (newVal: string) => void;
  options: {
    value: string;
    label: string;
  }[];
}) => {
  return (
    <label className="flex flex-col space-y-1">
      <span className="text-sm">{label}</span>
      <select
        className="border border-solid border-gray-40 p-2 font-pt-sans"
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </label>
  );
};
