import { usePagination } from 'hooks/usePagination';
import { Pagination } from 'pureUi/Pagination';
import React, { useEffect, useState } from 'react';
import { ENetworkRequestStatus, IHotel, makeBackendApi } from 'services/BackendApi';
import { ITableColumn } from 'ui/Table/types';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { isNil } from 'lodash-es';
import { Link } from 'ui/Link';
import * as Inputs from './components/Inputs';
import { TCountryCode } from 'interfaces';
import { useSelector } from 'react-redux';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';

const tableColumns: ITableColumn[] = [
  {
    id: 'name',
    label: 'Name',
    width: '100px',
    fieldToSortBy: 'name',
  },
  {
    id: 'countryCode',
    label: 'Country Code',
    width: '100px',
    fieldToSortBy: 'countryCode',
  },
  {
    id: 'region',
    label: 'Region',
    width: '100px',
  },
  {
    id: 'starRating',
    label: 'Star Rating',
    width: '100px',
  },
  {
    id: 'preferred',
    label: 'Preferred',
    width: '100px',
  },
  {
    id: 'live-rates',
    label: 'Live Rates',
    width: '100px',
  },
  {
    id: 'cta',
    label: '',
    width: '100px',
  },
];

export const List = () => {
  const backendApi = makeBackendApi();

  const countries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);
  const paginationData = usePagination({ initialPage: 1, initialPerPage: 10, initialTotal: 100 });
  const [hotels, setHotels] = useState<IHotel[]>([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc' as ESortOrder);

  const [filterName, setFilterName] = useState('');
  const [debouncedFilterName, setDebouncedFilterName] = useState('');
  const [filterCountryCode, setFilterCountryCode] = useState<TCountryCode | null>(null);
  const [getHotelsRequestStatus, setGetHotelsRequestStatus] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );

  useEffect(() => {
    setGetHotelsRequestStatus(ENetworkRequestStatus.PENDING);
    backendApi
      .hotelAdminGetList({
        page: paginationData.page,
        perPage: paginationData.perPage,
        sortBy,
        sortOrder,
        filterName: debouncedFilterName,
        countryCode: filterCountryCode,
      })
      .then(res => {
        setGetHotelsRequestStatus(ENetworkRequestStatus.SUCCESS);
        setHotels(res.data.data);
        paginationData.setTotal(res.data.meta.total);
      })
      .catch(error => {
        setGetHotelsRequestStatus(ENetworkRequestStatus.ERROR);
      });
  }, [paginationData.page, paginationData.perPage, sortBy, sortOrder, debouncedFilterName, filterCountryCode]);

  const tableRows = hotels.map(hotel => {
    return {
      id: hotel.uuid,
      cells: [
        { id: 'name', value: hotel.name },
        { id: 'countryCode', value: hotel.countryCode },
        { id: 'region', value: hotel.region },
        { id: 'starRating', value: hotel.starRating },
        { id: 'preferred', value: hotel.preferred ? 'Yes' : 'No' },
        { id: 'live-rates', value: !isNil(hotel.externalSystem) ? 'Yes' : 'No' },
        {
          id: 'cta',
          value: (
            <span className="flex space-x-2">
              <Link to={`/hotel-admin/${hotel.uuid}/edit`}>Edit Hotel</Link>
              <Link to={`/hotel-admin/${hotel.uuid}/edit-children`}>Edit Children</Link>
            </span>
          ),
        },
      ],
    };
  });

  return (
    <div className="container w-1280px mx-auto flex flex-col space-y-25px">
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels</span>
      </h1>

      <fieldset className="flex flex-row space-x-2 border border-gray-20 border-solid p-2 pb-4">
        <legend className="text-sm">Filters</legend>

        <Inputs.Input
          label="Name"
          value={filterName}
          className="min-w-[200px]"
          onDebounceChange={val => {
            setDebouncedFilterName(val);
          }}
          onChange={val => {
            setFilterName(val);
          }}
        />

        <Inputs.Dropdown
          label="Country"
          options={countries.map(c => ({ value: c.code, label: c.name }))}
          values={filterCountryCode ? [filterCountryCode] : []}
          className="min-w-[200px]"
          onChange={values => {
            const firstValue = values[0];
            if (isNil(firstValue)) {
              setFilterCountryCode(null);
            } else {
              setFilterCountryCode(firstValue as TCountryCode);
            }
          }}
          multiselectProps={{
            isSingleSelectMode: true,
            isCloseOnSelect: true,
            hideCheckboxes: true,
            isEnableFuzzySearch: true,
          }}
        />
      </fieldset>

      {getHotelsRequestStatus === ENetworkRequestStatus.ERROR && (
        <div className="flex flex-col items-center justify-center">
          <ErrorBar />
        </div>
      )}

      {getHotelsRequestStatus === ENetworkRequestStatus.PENDING && tableRows.length <= 0 && (
        <div className="flex flex-col items-center justify-center">
          <LoadingBar />
        </div>
      )}

      {tableRows.length >= 1 && (
        <Table
          columns={tableColumns}
          rows={tableRows}
          messageWhenEmpty="No results"
          loading={getHotelsRequestStatus === ENetworkRequestStatus.PENDING}
          onSort={(newSortBy, newSortOrder) => {
            setSortBy(newSortBy);
            setSortOrder(newSortOrder as ESortOrder);
          }}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      )}

      <Pagination
        currentPage={paginationData.page}
        itemsPerPage={paginationData.perPage}
        onItemsPerPageChange={paginationData.setPerPage}
        onPageSelect={paginationData.setPage}
        pageCount={paginationData.pageCount}
      />
    </div>
  );
};
