import React from 'react';
import {
  IAccomodationProductOptions,
  IHotel,
  IProduct,
  ISeasonalProductRate,
  ITransferProduct,
} from 'services/BackendApi';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';
import FluidButton from 'ui/FluidButton';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { SeasonalProductAddonRate } from './SeasonalProductAddonRate';
import * as Inputs from './Inputs';

export const TransferRateDetails = ({
  hotel,
  transfer: transfer,
  seasonalProductRate,
  bootstrapCountries,
  onUpdateTransferRate,
  onPatchSeasonalTransferRate,
}: {
  hotel: IHotel;
  transfer: ITransferProduct;
  seasonalProductRate: ISeasonalProductRate;
  bootstrapCountries: IBootstrapCountry[];
  onUpdateTransferRate: (field: keyof ISeasonalProductRate, value: any) => void;
  onPatchSeasonalTransferRate: () => void;
}) => {
  const allSeasons = hotel.seasons || [];
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Select
        label="Season"
        value={seasonalProductRate.seasonUuid}
        onChange={val => {
          onUpdateTransferRate('seasonUuid', val);
        }}
        options={allSeasons.map(season => {
          return {
            value: season.uuid,
            label: season.name,
          };
        })}
      />

      <Inputs.Checkbox
        label="Is on request"
        value={seasonalProductRate.isOnRequest}
        onChange={val => {
          onUpdateTransferRate('isOnRequest', val);
        }}
      />

      <Inputs.Input
        label="Rate"
        value={seasonalProductRate.rate}
        onChange={val => {
          onUpdateTransferRate('rate', val);
        }}
      />

      <Inputs.Input
        label="Markup Amount"
        value={seasonalProductRate.markupAmount}
        onChange={val => {
          onUpdateTransferRate('markupAmount', val);
        }}
      />

      <Inputs.RadioCollection
        label="Markup Type"
        onChange={val => {
          onUpdateTransferRate('markupType', val);
        }}
        collection={Inputs.markupTypes.map(markupType => {
          return {
            isChecked: markupType.value === seasonalProductRate.markupType,
            value: markupType.value,
            label: markupType.label,
          };
        })}
      />

      <Inputs.Input
        label="Internal description"
        value={seasonalProductRate.internalDescription || ''}
        onChange={val => {
          onUpdateTransferRate('internalDescription', val);
        }}
      />

      <Inputs.Input
        label="Payment terms"
        value={seasonalProductRate.paymentTerms}
        onChange={val => {
          onUpdateTransferRate('paymentTerms', val);
        }}
      />

      <Inputs.Input
        label="Cancellation policy"
        value={seasonalProductRate.cancellationPolicy}
        onChange={val => {
          onUpdateTransferRate('cancellationPolicy', val);
        }}
      />

      <Inputs.CountryList
        selectedCountries={seasonalProductRate.countries ? seasonalProductRate.countries : []}
        label="Countries"
        bootstrapCountries={bootstrapCountries}
        onChange={val => {
          onUpdateTransferRate('countries', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[250px] self-end"
        onClick={() => {
          onPatchSeasonalTransferRate();
        }}
      >
        Update Transfer Rate
      </FluidButton>
    </div>
  );
};
