import React from 'react';
import {
  IAccomodationProductOptions,
  IFineProduct,
  IHotel,
  IProduct,
  ISeasonalProductRate,
  ITransferProduct,
} from 'services/BackendApi';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';
import FluidButton from 'ui/FluidButton';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { SeasonalProductAddonRate } from './SeasonalProductAddonRate';
import * as Inputs from './Inputs';

export const FineRateDetails = ({
  hotel,
  fineProduct,
  seasonalProductRate,
  bootstrapCountries,
  onUpdateFineRate,
  onPatchSeasonalFineRate,
}: {
  hotel: IHotel;
  fineProduct: IFineProduct;
  seasonalProductRate: ISeasonalProductRate;
  bootstrapCountries: IBootstrapCountry[];
  onUpdateFineRate: (field: keyof ISeasonalProductRate, value: any) => void;
  onPatchSeasonalFineRate: () => void;
}) => {
  const allSeasons = hotel.seasons || [];
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Select
        label="Season"
        value={seasonalProductRate.seasonUuid}
        onChange={val => {
          onUpdateFineRate('seasonUuid', val);
        }}
        options={allSeasons.map(season => {
          return {
            value: season.uuid,
            label: season.name,
          };
        })}
      />

      <Inputs.Checkbox
        label="Is on request"
        value={seasonalProductRate.isOnRequest}
        onChange={val => {
          onUpdateFineRate('isOnRequest', val);
        }}
      />

      <Inputs.Input
        label="Markup Amount"
        value={seasonalProductRate.markupAmount}
        onChange={val => {
          onUpdateFineRate('markupAmount', val);
        }}
      />

      <Inputs.RadioCollection
        label="Markup Type"
        onChange={val => {
          onUpdateFineRate('markupType', val);
        }}
        collection={Inputs.markupTypes.map(markupType => {
          return {
            isChecked: markupType.value === seasonalProductRate.markupType,
            value: markupType.value,
            label: markupType.label,
          };
        })}
      />

      <Inputs.Input
        label="Internal description"
        value={seasonalProductRate.internalDescription || ''}
        onChange={val => {
          onUpdateFineRate('internalDescription', val);
        }}
      />

      <Inputs.Input
        label="Payment terms"
        value={seasonalProductRate.paymentTerms}
        onChange={val => {
          onUpdateFineRate('paymentTerms', val);
        }}
      />

      <Inputs.Input
        label="Cancellation policy"
        value={seasonalProductRate.cancellationPolicy}
        onChange={val => {
          onUpdateFineRate('cancellationPolicy', val);
        }}
      />

      <Inputs.CountryList
        selectedCountries={seasonalProductRate.countries ? seasonalProductRate.countries : []}
        label="Countries"
        bootstrapCountries={bootstrapCountries}
        onChange={val => {
          onUpdateFineRate('countries', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[250px] self-end"
        onClick={() => {
          onPatchSeasonalFineRate();
        }}
      >
        Update Fine Rate
      </FluidButton>
    </div>
  );
};
