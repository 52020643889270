import React from 'react';
import FluidButton from 'ui/FluidButton';

export const AddNewRate = () => {
  return (
    <FluidButton type="secondary" className="w-[200px] self-start">
      Add New Rate
    </FluidButton>
  );
};
