import React from 'react';
import * as _ from 'lodash-es';
import { SeasonalProductAddonRate } from './SeasonalProductAddonRate';
import { getCurrencySymbol } from 'utils';
import FluidButton from 'ui/FluidButton';
import { produce } from 'immer';
import { ISeasonalProductRate } from 'services/BackendApi';

export const RoomRateAddons = ({
  seasonalProductRate,
  hotel,
  onUpdateRoomRate,
  onPatchMealPlans,
}: {
  seasonalProductRate: any;
  hotel: any;
  onUpdateRoomRate: (field: keyof ISeasonalProductRate, value: any) => void;
  onPatchMealPlans: () => void;
}) => {
  const mealPlanRates = seasonalProductRate.seasonalProductAddonRates.filter(spar => spar.product.type === 'Meal Plan');

  const updateMealPlanRate = (mealPlanRateUuid: string, field: string, val: any) => {
    const indexOfMealPlanRate = seasonalProductRate.seasonalProductAddonRates!.findIndex(
      spar => spar.uuid === mealPlanRateUuid
    );

    const mealPlanRate = seasonalProductRate.seasonalProductAddonRates![indexOfMealPlanRate];

    const updatedMealPlanRate = produce(mealPlanRate, draftEpsRate => {
      _.set(draftEpsRate, field, val);
    });

    const updatedSeasonalProductAddonRates = produce(
      seasonalProductRate.seasonalProductAddonRates,
      draftSeasonalProductAddonRates => {
        // @ts-ignore
        draftSeasonalProductAddonRates[indexOfMealPlanRate] = updatedMealPlanRate;
      }
    );

    onUpdateRoomRate('seasonalProductAddonRates', updatedSeasonalProductAddonRates);
  };

  return (
    <div className="flex flex-col space-y-2 w-full">
      {_.orderBy(mealPlanRates, ['product.name'], ['asc']).map(mealPlanRate => {
        const sparHasAges = mealPlanRate?.product?.options?.ages?.length > 0;

        return (
          <div key={mealPlanRate.uuid} className="bg-gray-20 p-4">
            <span>
              <span>{mealPlanRate.product.name}</span>
              {mealPlanRate.rate && mealPlanRate.rate !== '' && (
                <span className="ml-2 font-bold">
                  {getCurrencySymbol(hotel?.defaultCurrency!)}
                  {mealPlanRate.rate}
                </span>
              )}
            </span>
            {sparHasAges && (
              <>
                <SeasonalProductAddonRate
                  addonRate={mealPlanRate}
                  onUpdate={(field, value) => {
                    updateMealPlanRate(mealPlanRate.uuid, field as string, value);
                  }}
                  ageBrackets={mealPlanRate?.product?.options?.ages}
                />
              </>
            )}
          </div>
        );
      })}

      <FluidButton
        className="w-[200px] mt-4 self-start"
        type="secondary"
        onClick={() => {
          alert('Not implemented yet - coming soon!');
        }}
      >
        Add New Meal Plan Rate
      </FluidButton>

      <FluidButton
        className="w-[200px] mt-4 self-end"
        type="primary"
        onClick={() => {
          onPatchMealPlans();
        }}
      >
        Update Meal Plan Rates
      </FluidButton>
    </div>
  );
};
