import React from 'react';
import { ISupplementProduct } from 'services/BackendApi';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';

export const Supplement = ({
  supplement,
  onUpdate,
  onPatchSupplement,
}: {
  supplement: ISupplementProduct;
  onUpdate: (field: keyof any, value: any) => void;
  onPatchSupplement: () => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate('name', val);
        }}
        value={supplement.name}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          onUpdate('meta', {
            ...supplement.meta,
            description: val,
          });
        }}
        value={supplement.meta.description}
        rows={5}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.categoryTypes].map(mt => {
          return {
            isChecked: supplement.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate('category', val);
        }}
      />

      <Inputs.AgeGroups
        label={'Age Groups'}
        collection={supplement.options.ages}
        onChange={newList => {
          onUpdate('options', {
            ...supplement.options,
            ages: newList,
          });
        }}
      />

      <FluidButton
        type="primary"
        className="self-end w-[200px] mt-4"
        onClick={() => {
          onPatchSupplement();
        }}
      >
        Update Supplement
      </FluidButton>
    </div>
  );
};
