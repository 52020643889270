import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  EHotelUploadTags,
  ENetworkRequestStatus,
  EUploadTag,
  IHotel,
  IUploadFileInfo,
  makeBackendApi,
} from 'services/BackendApi';
import { Link } from 'ui/Link';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { SimpleTabs } from 'ui/SimpleTabs';
import { produce } from 'immer';
import * as Inputs from './components/Inputs';
import { useSelector, useDispatch } from 'react-redux';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import { FiltersCategory } from 'pureUi/SearchSettings/FiltersCategory';
import FluidButton from 'ui/FluidButton';
import currencies from 'config/data/currencies.json';
import { enqueueNotification } from 'store/modules/ui';
import { SvgIcon } from 'ui/SvgIcon';
import Star from 'ui/Icons/star.component.svg';
import CloseBold from 'ui/Icons/close-bold.component.svg';
import { Uploads } from './components/Uploads';

const isUploadAnImage = (upload: IUploadFileInfo) => {
  return upload.tag === EUploadTag.PHOTO || upload.tag === EUploadTag.FEATURED_PHOTO;
};

const HotelDetailsTab = ({
  hotel,
  onUpdate,
  filtersCategories,
  starRatings,
  onPatchHotel,
  patchingRequest,
  setFeaturedPhoto,
  deleteUpload,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  filtersCategories: any[];
  starRatings: string[];
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
  patchingRequest: ENetworkRequestStatus;
  setFeaturedPhoto: (featuredPhoto: IUploadFileInfo) => void;
  deleteUpload: (upload: IUploadFileInfo) => void;
}) => {
  const bootstrapCountries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);

  const currenciesArray = useMemo(
    () =>
      Object.keys(currencies).map(key => {
        return {
          code: key,
          name: currencies[key].name,
          symbol: currencies[key].symbol,
        };
      }),
    [currencies]
  );

  const imageUploads = hotel.uploads?.filter(isUploadAnImage);
  const documentUploads = hotel.uploads?.filter(upload => !isUploadAnImage(upload));

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input label={'Name'} value={hotel.name} onChange={val => onUpdate('name', val)} />

      <Inputs.Dropdown
        label={'Country Code'}
        values={hotel.countryCode ? [hotel.countryCode] : []}
        options={bootstrapCountries.map(x => {
          return { value: x.code, label: x.name };
        })}
        onChange={() => {}}
      />

      <Inputs.Input label={'Hotel ID'} value={hotel.externalHotelId || ''} onChange={val => onUpdate('hotelId', val)} />

      <Inputs.Checkbox
        label={'Enabled'}
        value={hotel.enabled || false}
        onChange={newBool => {
          onUpdate('enabled', newBool);
        }}
      />

      <Inputs.Input label={'Region'} value={hotel.region || ''} onChange={val => onUpdate('region', val)} />

      <Inputs.TextArea
        label={'Description'}
        value={hotel.description || ''}
        onChange={val => onUpdate('description', val)}
      />

      <Inputs.TextArea
        label={'Additional Info'}
        value={hotel.additionalInfo || ''}
        onChange={val => onUpdate('additionalInfo', val)}
        rows={5}
      />

      <Inputs.CheckboxCollection
        label={'Filters'}
        collection={filtersCategories.map(fc => {
          return { isChecked: hotel.filters.includes(fc.filterEnumName), label: fc.filterEnumName };
        })}
        onChange={(filterName, checked) => {
          if (checked) {
            onUpdate('filters', [...hotel.filters, filterName]);
          } else {
            onUpdate(
              'filters',
              hotel.filters.filter(f => f !== filterName)
            );
          }
        }}
      />

      <Inputs.RadioCollection
        label={'Star Rating'}
        collection={starRatings.map(sr => {
          const isChecked = hotel.starRating === sr;
          return { isChecked, value: sr, label: sr };
        })}
        onChange={starRating => {
          onUpdate('starRating', starRating);
        }}
      />

      <Inputs.RadioCollection
        label={'Currency'}
        collection={['USD', 'EUR'].map(currency => {
          const isChecked = hotel.defaultCurrency === currency;
          return { isChecked, value: currency, label: currency };
        })}
        onChange={newDefaultCurrency => {
          console.log('newDefaultCurrency', newDefaultCurrency);
          onUpdate('defaultCurrency', newDefaultCurrency);
        }}
      />

      <Inputs.Checkbox
        label={'Suitable for honeymooners'}
        value={hotel.suitableForHoneymooners || false}
        onChange={newBool => {
          onUpdate('suitableForHoneymooners', newBool);
        }}
      />

      <Inputs.Checkbox
        label={'Preferred'}
        value={hotel.preferred || false}
        onChange={newBool => {
          onUpdate('preferred', newBool);
        }}
      />

      <Inputs.Checkbox
        label={'Disabled for travel partners'}
        value={hotel.disabledForTa || false}
        onChange={newBool => {
          onUpdate('disabledForTa', newBool);
        }}
      />

      <Inputs.RadioCollection
        label={'External System'}
        collection={[
          {
            value: null,
            label: 'None',
          },
          {
            value: 'derbysoft',
            label: 'Derbysoft',
          },
          {
            value: 'illusions',
            label: 'Illusions',
          },
          {
            value: 'mock',
            label: 'Mock',
          },
        ].map(es => {
          const isChecked = hotel.externalSystem === es.value;
          return { isChecked, value: es.value, label: es.label };
        })}
        onChange={externalSystem => {
          console.log('externalSystem', externalSystem);
          onUpdate('externalSystem', externalSystem);
        }}
      />

      <Inputs.Input
        label={'External Supplier ID'}
        value={hotel.externalSupplierId || ''}
        onChange={val => onUpdate('externalSupplierId', val)}
      />

      <Inputs.Input
        label={'External Hotel ID'}
        value={hotel.externalHotelId || ''}
        onChange={val => onUpdate('externalHotelId', val)}
      />

      <Inputs.Dropdown
        label={'External Rate Currency'}
        values={hotel.externalCurrency ? [hotel.externalCurrency] : []}
        options={currenciesArray.map(c => {
          return { value: c.code, label: c.name };
        })}
        onChange={sv => {
          if (sv.length <= 0) {
            onUpdate('externalCurrency', null);
          } else {
            onUpdate('externalCurrency', sv[0]);
          }
        }}
        multiselectProps={{
          isSingleSelectMode: true,
          isCloseOnSelect: true,
          hideCheckboxes: true,
          isEnableFuzzySearch: true,
        }}
      />

      <Inputs.Input
        label={'Fuel Charge'}
        value={hotel.fuelCharge || ''}
        onChange={val => onUpdate('fuelCharge', val)}
      />

      <Inputs.Input
        label={'Location (in lat/long format)'}
        value={hotel.location || ''}
        onChange={val => onUpdate('location', val)}
      />

      <details className="border border-solid border-gray-20 p-2">
        <summary className="cursor-pointer">Images</summary>
        <Uploads uploads={imageUploads || []} setFeaturedPhoto={setFeaturedPhoto} deleteUpload={deleteUpload} />
        <FluidButton
          type="secondary"
          className="w-[300px] mt-4"
          onClick={() => {
            alert('not implemented! come back soon!');
          }}
        >
          Add Image
        </FluidButton>
      </details>

      <details className="border border-solid border-gray-20 p-2">
        <summary className="cursor-pointer">Documents</summary>
        <Uploads uploads={documentUploads || []} setFeaturedPhoto={setFeaturedPhoto} deleteUpload={deleteUpload} />
        <FluidButton
          type="secondary"
          className="w-[300px] mt-4"
          onClick={() => {
            alert('not implemented! come back soon!');
          }}
        >
          Add Document
        </FluidButton>
      </details>

      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        isLoading={patchingRequest === ENetworkRequestStatus.PENDING}
        disabled={patchingRequest === ENetworkRequestStatus.PENDING}
        onClick={() => {
          onPatchHotel({
            description: hotel.description,
          });
        }}
      >
        Update Hotel Details
      </FluidButton>
    </div>
  );
};

const AmenitiesTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
  patchingRequest,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
  patchingRequest: ENetworkRequestStatus;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.CrudList
        collection={hotel.amenities}
        className="w-full"
        onChange={newList => {
          onUpdate('amenities', newList);
        }}
      />
      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          onPatchHotel({
            amenities: hotel.amenities,
          });
        }}
      >
        Update Amenities
      </FluidButton>
    </div>
  );
};

const HighlightsTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.CrudList
        collection={hotel.highlights}
        className="w-full"
        onChange={newList => {
          onUpdate('highlights', newList);
        }}
      />
      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          onPatchHotel({
            highlights: hotel.highlights,
          });
        }}
      >
        Update Highlights
      </FluidButton>
    </div>
  );
};

const OverviewTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.CrudList
        collection={hotel.overview}
        className="w-full"
        onChange={newList => {
          onUpdate('overview', newList);
        }}
      />
      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          onPatchHotel({
            overview: hotel.overview,
          });
        }}
      >
        Update Overview
      </FluidButton>
    </div>
  );
};

const PoliciesRestrictionsTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.CrudList
        collection={hotel.policiesAndRestrictions || []}
        className="w-full"
        onChange={newList => {
          onUpdate('policiesAndRestrictions', newList);
        }}
      />
      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          onPatchHotel({
            policiesAndRestrictions: hotel.policiesAndRestrictions,
          });
        }}
      >
        Update Policies & Restrictions
      </FluidButton>
    </div>
  );
};

const ContactDetailsTab = ({
  hotel,
  onUpdate,
  onPatchHotel,
}: {
  hotel: IHotel;
  onUpdate: (field: keyof IHotel, value: any) => void;
  onPatchHotel: (updatedData: Partial<IHotel>) => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input label={'Email'} value={hotel.email} onChange={val => onUpdate('email', val)} />

      <Inputs.Input
        label={'Reservation Emails'}
        value={hotel.reservationEmails || ''}
        onChange={val => onUpdate('reservationEmails', val)}
      />

      <Inputs.Input
        label={'Phone number'}
        value={hotel.phoneNumber || ''}
        onChange={val => onUpdate('phoneNumber', val)}
      />

      <Inputs.TextArea label={'Address'} value={hotel.address || ''} onChange={val => onUpdate('address', val)} />

      <hr />
      <FluidButton
        type="primary"
        className="self-end w-[300px] mt-4"
        onClick={() => {
          onPatchHotel({
            email: hotel.email,
            reservationEmails: hotel.reservationEmails,
            phoneNumber: hotel.phoneNumber,
            address: hotel.address,
          });
        }}
      >
        Update Policies & Restrictions
      </FluidButton>
    </div>
  );
};

export const Edit = () => {
  const match = useRouteMatch<{ hotelUuid: string }>();
  const hotelUuid = match.params.hotelUuid;

  const backendApi = makeBackendApi();
  const dispatch = useDispatch();

  const [hotel, setHotel] = useState<IHotel | null>(null);
  const [filtersCategories, setFiltersCategories] = useState<any[]>([]);
  const [starRatings, setStarRatings] = useState<string[]>([]);
  const [draftHotel, setDraftHotel] = useState<IHotel | null>(null);

  const [getHotelRequest, setGetHotelRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);
  const [patchHotelRequest, setPatchHotelRequest] = useState(ENetworkRequestStatus.IDLE);

  const retrieveHotel = async () => {
    setGetHotelRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .hotelAdminGetHotel(hotelUuid, ['uploads'])
      .then(res => {
        setGetHotelRequest(ENetworkRequestStatus.SUCCESS);
        setHotel(res.data.data);
        setDraftHotel(res.data.data);
      })
      .catch(error => {
        setGetHotelRequest(ENetworkRequestStatus.ERROR);
      });
  };

  // get the hotel
  useEffect(() => {
    retrieveHotel();
  }, [hotelUuid]);

  // get the options and filters and stuff
  useEffect(() => {
    backendApi.hotelAdminGetOptions().then(res => {
      // setAdminOptions(res.data.data);
      setFiltersCategories(res.data.data.filtersCategories.map(fc => fc.filters).flat());
      setStarRatings(res.data.data.starRatings);
    });
  }, []);

  if (getHotelRequest === ENetworkRequestStatus.PENDING || !hotel || !draftHotel) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  const updateDraftHotelValue = (field, value) => {
    const newHotel = produce(draftHotel!, _draftHotel => {
      _draftHotel[field] = value;
    });
    setDraftHotel(newHotel);
  };

  const patchHotel = async (updatedData: Partial<IHotel>) => {
    setPatchHotelRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .hotelAdminPatchHotel(hotel.uuid, updatedData)
      .then(data => {
        setPatchHotelRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Hotel updated successfully`,
            options: { variant: 'success' },
          })
        );
      })
      .catch(error => {
        console.error('error', error);
        setPatchHotelRequest(ENetworkRequestStatus.ERROR);
      });
  };

  const setFeaturedPhoto = async (featuredPhoto: IUploadFileInfo) => {
    setPatchHotelRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .hotelAdminSetFeaturedPhoto(featuredPhoto)
      .then(res => {
        setPatchHotelRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Featured Photo updated successfully`,
            options: { variant: 'success' },
          })
        );

        // then update the draft hotel so the right upload is marked as featured
        const updatedHotel = produce(hotel, draftHotel => {
          const originalFeaturedPhotoIndex = draftHotel.uploads?.findIndex(u => u.tag === EUploadTag.FEATURED_PHOTO);
          if (originalFeaturedPhotoIndex !== undefined) {
            draftHotel.uploads![originalFeaturedPhotoIndex].tag = EUploadTag.PHOTO;
          }
          const newFeaturedPhotoIndex = draftHotel.uploads?.findIndex(u => u.uuid === featuredPhoto.uuid);
          if (newFeaturedPhotoIndex !== undefined) {
            draftHotel.uploads![newFeaturedPhotoIndex].tag = EUploadTag.FEATURED_PHOTO;
          }
        });
        setHotel({
          ...updatedHotel,
        });
        setDraftHotel({
          ...updatedHotel,
        });
      })
      .catch(error => {
        console.error('error', error);
        setPatchHotelRequest(ENetworkRequestStatus.ERROR);
      });
  };

  const deleteUpload = async (upload: IUploadFileInfo) => {
    setPatchHotelRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .deleteUpload(upload.uuid)
      .then(res => {
        setPatchHotelRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Upload deleted successfully`,
            options: { variant: 'success' },
          })
        );

        // remove the upload from the draft hotel
        const updatedHotel = produce(hotel, draftHotel => {
          draftHotel.uploads = draftHotel.uploads?.filter(u => u.uuid !== upload.uuid);
        });
        setHotel({
          ...updatedHotel,
        });
        setDraftHotel({
          ...updatedHotel,
        });
      })
      .catch(error => {
        console.error('error', error);
        setPatchHotelRequest(ENetworkRequestStatus.ERROR);
      });
  };

  return (
    <div className="container w-1280px mx-auto">
      <Link to="/hotel-admin">Back to Hotels</Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels - Editing "{hotel.name}"</span>
      </h1>
      <Link to={`/hotel-admin/${hotel.uuid}/edit-children`}>Edit Hotel Children</Link>

      <SimpleTabs
        tabConfig={[
          {
            title: 'Hotel Details',
            name: 'hotel-details',
            styles: 'w-[350px]',
            renderContent: () => (
              <HotelDetailsTab
                hotel={draftHotel!}
                onUpdate={updateDraftHotelValue}
                filtersCategories={filtersCategories}
                starRatings={starRatings}
                onPatchHotel={patchHotel}
                patchingRequest={patchHotelRequest}
                setFeaturedPhoto={setFeaturedPhoto}
                deleteUpload={deleteUpload}
              />
            ),
          },
          {
            title: 'Amenities',
            name: 'amenities',
            styles: 'w-[150px]',
            renderContent: () => (
              <AmenitiesTab
                hotel={draftHotel}
                onUpdate={updateDraftHotelValue}
                onPatchHotel={patchHotel}
                patchingRequest={patchHotelRequest}
              />
            ),
          },
          {
            title: 'Highlights',
            name: 'highlights',
            styles: 'w-[150px]',
            renderContent: () => (
              <HighlightsTab hotel={draftHotel!} onUpdate={updateDraftHotelValue} onPatchHotel={patchHotel} />
            ),
          },
          {
            title: 'Overview',
            name: 'overview',
            styles: 'w-[150px]',
            renderContent: () => (
              <OverviewTab hotel={draftHotel!} onUpdate={updateDraftHotelValue} onPatchHotel={patchHotel} />
            ),
          },
          {
            title: 'Policies & Restrictions',
            name: 'policies-restrictions',
            styles: 'w-[450px]',
            renderContent: () => (
              <PoliciesRestrictionsTab hotel={draftHotel!} onUpdate={updateDraftHotelValue} onPatchHotel={patchHotel} />
            ),
          },
          {
            title: 'Contact Details',
            name: 'contact-details',
            styles: 'w-[350px]',
            renderContent: () => (
              <ContactDetailsTab hotel={draftHotel!} onUpdate={updateDraftHotelValue} onPatchHotel={patchHotel} />
            ),
          },
        ]}
      />
    </div>
  );
};
