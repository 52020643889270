import React from 'react';
import * as Inputs from './Inputs';
import {
  IAccomodationProductOptions,
  IHotel,
  IProduct,
  ISeasonalProductAddonRate,
  ISeasonalProductRate,
} from 'services/BackendApi';
import FluidButton from 'ui/FluidButton';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { SeasonalProductAddonRate } from './SeasonalProductAddonRate';

export const RoomRateDetails = ({
  hotel,
  room,
  seasonalProductRate,
  bootstrapCountries,
  onUpdateRoomRate,
  onPatchSeasonalRoomRate,
}: {
  hotel: IHotel;
  room: IProduct<IAccomodationProductOptions>;
  seasonalProductRate: ISeasonalProductRate;
  bootstrapCountries: IBootstrapCountry[];
  onUpdateRoomRate: (field: keyof ISeasonalProductRate, value: any) => void;
  onPatchSeasonalRoomRate: () => void;
}) => {
  const allSeasons = hotel.seasons || [];
  const matchingSeason = allSeasons.find(season => season.uuid === seasonalProductRate.seasonUuid);

  const markupTypes = [
    { value: 'flat', label: 'Flat' },
    { value: 'percentage', label: 'Percentage' },
  ];

  const extraPersonSupplementRate: ISeasonalProductAddonRate | null = (
    seasonalProductRate.seasonalProductAddonRates || []
  ).filter(spar => spar.product.type !== 'Meal Plan')[0];

  const updateEpsRate = (field: string, val: any) => {
    const indexOfEpsRate = seasonalProductRate.seasonalProductAddonRates!.findIndex(
      spar => spar.product.name === 'Extra Person Supplement'
    );

    const epsRate = seasonalProductRate.seasonalProductAddonRates![indexOfEpsRate];

    const updatedEpsRate = produce(epsRate, draftEpsRate => {
      _.set(draftEpsRate, field, val);
    });

    const updatedSeasonalProductAddonRates = produce(
      seasonalProductRate.seasonalProductAddonRates,
      draftSeasonalProductAddonRates => {
        // @ts-ignore
        draftSeasonalProductAddonRates[indexOfEpsRate] = updatedEpsRate;
      }
    );

    onUpdateRoomRate('seasonalProductAddonRates', updatedSeasonalProductAddonRates);
  };

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Select
        label="Season"
        value={seasonalProductRate.seasonUuid}
        onChange={val => {
          onUpdateRoomRate('seasonUuid', val);
        }}
        options={allSeasons.map(season => {
          return {
            value: season.uuid,
            label: season.name,
          };
        })}
      />

      <Inputs.Checkbox
        label="Is on request"
        value={seasonalProductRate.isOnRequest}
        onChange={val => {
          onUpdateRoomRate('isOnRequest', val);
        }}
      />

      <Inputs.Input
        label="Rate"
        value={seasonalProductRate.rate}
        onChange={val => {
          onUpdateRoomRate('rate', val);
        }}
      />

      <Inputs.Input
        label="Markup Amount"
        value={seasonalProductRate.markupAmount}
        onChange={val => {
          onUpdateRoomRate('markupAmount', val);
        }}
      />

      <Inputs.RadioCollection
        label="Markup Type"
        onChange={val => {
          onUpdateRoomRate('markupType', val);
        }}
        collection={markupTypes.map(markupType => {
          return {
            isChecked: markupType.value === seasonalProductRate.markupType,
            value: markupType.value,
            label: markupType.label,
          };
        })}
      />

      <Inputs.Input
        label="Minimum advance"
        value={seasonalProductRate.minimumAdvance.toString()}
        onChange={val => {
          onUpdateRoomRate('minimumAdvance', val);
        }}
      />

      <Inputs.Input
        label="Minimum nights"
        value={seasonalProductRate.minimumNights.toString()}
        onChange={val => {
          onUpdateRoomRate('minimumNights', val);
        }}
      />

      <Inputs.Input
        label="Internal description"
        value={seasonalProductRate.internalDescription || ''}
        onChange={val => {
          onUpdateRoomRate('internalDescription', val);
        }}
      />

      <Inputs.Input
        label="Payment terms"
        value={seasonalProductRate.paymentTerms}
        onChange={val => {
          onUpdateRoomRate('paymentTerms', val);
        }}
      />

      <Inputs.Input
        label="Cancellation policy"
        value={seasonalProductRate.cancellationPolicy}
        onChange={val => {
          onUpdateRoomRate('cancellationPolicy', val);
        }}
      />

      <p>Extra person supplement rates</p>
      <SeasonalProductAddonRate
        addonRate={extraPersonSupplementRate}
        onUpdate={updateEpsRate}
        ageBrackets={room.options.ages}
      />

      <Inputs.CountryList
        selectedCountries={seasonalProductRate.countries ? seasonalProductRate.countries : []}
        label="Countries"
        bootstrapCountries={bootstrapCountries}
        onChange={val => {
          onUpdateRoomRate('countries', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[250px] self-end"
        onClick={() => {
          onPatchSeasonalRoomRate();
        }}
      >
        Update Rate & EPS Addon Rate
      </FluidButton>
    </div>
  );
};
