import React from 'react';
import { ChildItem } from './ChildItem';
import FluidButton from 'ui/FluidButton';

interface iChildrenListProps {
  hotel: any;
  singularNoun: string;
  childList: any[];
  onOpen: (childItem: any) => void;
  contentRenderer: (childItem: any) => React.ReactElement;
  renderChildSubProducts: any[];
  onCreateTrigger?: () => void;
}

export const ChildrenList = (props: iChildrenListProps) => {
  return (
    <div className="w-full">
      <div className="flex flex-col space-y-2 w-full">
        {props.childList?.map(childItem => {
          const draftChildItem = {
            ...childItem,
          };
          return (
            <ChildItem
              key={childItem.uuid}
              onOpen={props.onOpen}
              title={childItem.name}
              childItem={draftChildItem}
              contentRenderer={props.contentRenderer}
              renderChildSubProducts={props.renderChildSubProducts}
            />
          );
        })}

        <hr />
      </div>
      <FluidButton
        onClick={() => {
          props.onCreateTrigger ? props.onCreateTrigger() : null;
        }}
        type="secondary"
        className="w-[200px] mt-4 self-start"
      >
        Create new {props.singularNoun}
      </FluidButton>
    </div>
  );
};
