import React from 'react';
import { IHotel, IMealPlanProductOptions, IProduct } from 'services/BackendApi';
import * as Inputs from './Inputs';
import produce from 'immer';
import { isNil } from 'lodash-es';
import FluidButton from 'ui/FluidButton';

/**
 * perAccommodationProduct: 'Per accomodation',
  perBooking: 'Per booking',
  perNight: 'Per night',
  perPerson: 'Per person',
  perPersonPerNight: 'Per person per night',

  RO: 'Room Only',
  BB: 'Breakfast',
  HB: 'Half board',
  FB: 'Full board',
  AI: 'All inclusive',
 */

export interface iMealPlanProps {
  hotel: IHotel;
  mealPlan: IProduct<IMealPlanProductOptions>;
  onUpdate: (hotelField: keyof IProduct<IMealPlanProductOptions>, newValue: any) => void;
  onPatchMealPlan: (mealPlan: Partial<IProduct<IMealPlanProductOptions>>) => void;
}

const MealPlanTypes = {
  RO: 'Room Only',
  BB: 'Breakfast',
  HB: 'Half board',
  FB: 'Full board',
  AI: 'All inclusive',
};

const CategoryTypes = {
  perAccommodationProduct: 'Per accomodation',
  perBooking: 'Per booking',
  perNight: 'Per night',
  perPerson: 'Per person',
  perPersonPerNight: 'Per person per night',
};

export const MealPlan = (props: iMealPlanProps) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label="Name"
        onChange={val => {
          props.onUpdate('name', val);
        }}
        value={props.mealPlan.name}
      />

      <Inputs.TextArea
        label="Description"
        onChange={val => {
          props.onUpdate('meta', {
            ...props.mealPlan.meta,
            description: val,
          });
        }}
        value={props.mealPlan.meta.description}
      />

      <div className="flex space-x-14">
        <Inputs.RadioCollection
          label="Meal Type"
          collection={Object.keys(MealPlanTypes).map(k => {
            return {
              isChecked: props.mealPlan.meta.categoryType === k,
              value: k,
              label: MealPlanTypes[k],
            };
          })}
          onChange={val => {
            console.log('val', val);
            props.onUpdate('meta', {
              ...props.mealPlan.meta,
              categoryType: val,
            });
          }}
        />

        <Inputs.RadioCollection
          label="Category"
          disabled={true}
          collection={Object.keys(CategoryTypes).map(k => {
            return {
              isChecked: props.mealPlan.category === k,
              value: k,
              label: CategoryTypes[k],
            };
          })}
          onChange={val => {
            props.onUpdate('category', val);
          }}
        />
      </div>

      <Inputs.AgeGroups
        label="Age Groups"
        collection={props.mealPlan.options.ages}
        onChange={newAgeGroups => {
          props.onUpdate('options', {
            ...props.mealPlan.options,
            ages: newAgeGroups,
          });
        }}
      />

      <FluidButton
        type="primary"
        className="w-[200px] self-end"
        onClick={() => {
          props.onPatchMealPlan(props.mealPlan);
        }}
      >
        Update Meal Plan
      </FluidButton>
    </div>
  );
};
