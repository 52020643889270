import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { GeneralModal } from 'ui/GeneralModal';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { AddAccommodationModalFilters, GA_FLOW_TAG } from './AddAccommodationModalFilters';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { TStartAndEndDate } from './types';
import { IHeadlineLineItemBreakdownComponent } from 'ui/HeadlineLineItemBreakdown';
import { AddAccommodationModalTable } from './AddAccommodationModalTable';


export interface IAddAccommodationModalProps {
  bookingUuid: string;
  bookingCurrencySymbol: IHeadlineLineItemBreakdownComponent['bookingCurrency'];
}

export const AddAccommodationModal = ({bookingUuid, bookingCurrencySymbol}: IAddAccommodationModalProps) => {
  const { dynamicParameters } = useDynamicParameters();
  const dispatch = useDispatch();
  const error = useSelector(BreakdownSelectors.BAMErrorSelector);
  const isAccommodationModalOpen = useSelector(BreakdownSelectors.breakdownAncillaryAccommodationModalToggleSelector);
  const hotelUuid = useSelector(BreakdownSelectors.BAMHotelFilterSelector);
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);
  const accommodations = useSelector(BreakdownSelectors.BAMAccommodationsSelector);
  const searchResultsCount = searchAccommodationsRequest === ENetworkRequestStatus.PENDING ? '(Searching...)' : `(${accommodations.length || 'There are no products for the searched criteria'})`;

  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const [startAndEndDate, setStartAndEndDate] = useState<TStartAndEndDate>({
    startDate: topNavigationData?.arrivalDate as string,
    endDate: topNavigationData?.departureDate as string,
  });

  useEffect(() => {
    dispatch(BreakdownActions.setBAMDatesFilterAction(startAndEndDate.startDate, startAndEndDate.endDate));
  }, [startAndEndDate, dispatch]);

  const handleCloseModal = useCallback(() => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_close`, {});
    }
    dispatch(BreakdownActions.setAddAncillaryAccommodationModalToggleAction(false));

    dispatch(BreakdownActions.setBAMGuestAgesFilterAction({
      numberOfAdults: 2, 
      agesOfAllChildren: []
    }));
    dispatch(BreakdownActions.setBAMSelectedAccommodationAction(''));
    setStartAndEndDate({
      startDate: topNavigationData?.arrivalDate as string,
      endDate: topNavigationData?.departureDate as string,
    });
  }, [dispatch, dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID, GA_FLOW_TAG, topNavigationData]);

  const handleChooseAccommodation = useCallback((accommodationUuid: string) => {
    dispatch(BreakdownActions.setBAMSelectedAccommodationAction(accommodationUuid));
  }, [dispatch]);

  if (!isAccommodationModalOpen) {
    return null;
  }

  if (!topNavigationData) {
    return (
      <GeneralModal
        onClose={handleCloseModal}
        modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
        shouldCloseByClickingOutside={false}
      >
        <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>
        <div>
          <LoadingBar />
        </div>
      </GeneralModal>
    )
  }

  if (error || !bookingCurrencySymbol) {
    return (
      <GeneralModal
        onClose={handleCloseModal}
        modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
        shouldCloseByClickingOutside={false}
      >
        <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>
        <div>
          <ErrorBar message={error ?? 'Failed to load content'} />
        </div>
      </GeneralModal>
    );
  }

  return (
    <GeneralModal
      onClose={handleCloseModal}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      <h2 className="m-0 p-0 font-noe-display text-21px leading-27px font-normal mb-25px">Add Accommodation</h2>


      <div className="all-panels flex space-x-[30px] overflow-x-hidden flex-grow h-[calc(100%-65px)]">
        <div className="left-panel flex flex-col flex-shrink space-y-25px">
          <AddAccommodationModalFilters
            hotelUuid={hotelUuid}
            bookingCurrencySymbol={bookingCurrencySymbol}
            bookingUuid={bookingUuid}
            setStartAndEndDate={setStartAndEndDate}
            error={error}
          />
            
          <div className={classnames('font-pt-sans flex gap-[5px] mt-[25px]', { 'opacity-50': searchAccommodationsRequest === ENetworkRequestStatus.PENDING })}>
            <span className="font-bold text-black mb-5px block">Search Results</span>
            <span className="font-normal">{searchResultsCount}</span>
          </div>

          <AddAccommodationModalTable
            onChooseAccommodation={handleChooseAccommodation}
            bookingCurrencySymbol={bookingCurrencySymbol}
          />
        </div>

      </div>
    </GeneralModal>
  );
};
