import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';
import { IHotel, ISeason } from 'services/BackendApi';
import { Season } from './Season';

export const CreateSeasonModal = ({
  onConfirm,
  onClose,
  hotel,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
}) => {
  const [draftSeason, setDraftSeason] = React.useState<Partial<ISeason>>({
    createdAt: '',
    updatedAt: '',
    hotelUuid: hotel.uuid,
    seasonDates: [],
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-clip"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new Season</h1>
      <Season
        hotel={hotel}
        season={draftSeason}
        onUpdate={(field, value) => {
          setDraftSeason({
            ...draftSeason,
            [field]: value,
          });
        }}
        onCta={() => {
          onConfirm(draftSeason);
        }}
        ctaLabel="Create Season"
      />
    </GeneralModal>
  );
};
