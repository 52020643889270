import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IHeadlineLineItemBreakdown,
  EPurchaseCostReviewStatus,
  IHeadlineLineItemBreakdownAccommodationLineItem,
  ERateSource,
} from 'services/BookingManagerApi';
import { formatPrice, getCurrency, getCurrencyBySymbol, getCurrencySymbol } from 'utils';
import { DecimalInput } from '../stateful/DecimalInput';
import styled from 'styled-components';
import { PurchaseCostReviewStatus } from 'ui/PurchaseCostReviewStatus';
import classnames from 'classnames';
import { AccommodationBlock } from './AccommodationBlock';
import { FinanceAdjustmentProductsBlock, ProductsBlock } from './ProductsBlock';
import FluidButton from 'ui/FluidButton';
import { ENetworkRequestStatus } from 'services/BackendApi';
import * as HotelDetailsActions from 'store/modules/bookingManager/subdomains/hotelDetails/actions';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { AddActivityModalContainer } from '../../containers/AddActivityModalContainer/AddActivityModalContainer';
import { AddTransferModalContainer } from 'containers/AddTransferModalContainer/AddTransferModalContainer';
import { AddGroundServiceModalContainer } from 'containers/AncillaryGroundServices/AddGroundServiceModalContainer';
import { GenericProductsBlock } from './GenericProductBlock';
import { EditAncillaryProductModalContainer } from 'containers/EditAncillaryProductModalContainer/EditAncillaryProductModalContainer';
import { isNil } from 'lodash-es';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import { AddAccommodationModal } from 'ui/AddAccommodationModal/AddAccommodationModal';
import { VerticalSpace } from 'ui/VerticalSpace';
import { Multiselect } from 'ui/Multiselect';
import { RefreshComponent } from 'ui/Icons';
import { getExchangeCurrencyRequestAction, refreshExchangeCurrencyAction, resetExchangeCurrencyAction } from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { TCurrencyCode } from 'interfaces';
import { HidingTooltip } from 'ui/Tooltip';

export interface IHeadlineLineItemBreakdownComponent {
  bookingUuid: string;
  className?: string;
  headlineLineItemBreakdown: IHeadlineLineItemBreakdown | null;
  bookingCurrency?: string | null;
  addHeadlineLineItemAccommodationAction: typeof BreakdownActions.addHeadlineLineItemAccommodationAction;
  setHeadlineLineItemFieldAction: typeof BreakdownActions.setHeadlineLineItemFieldAction;
  addHeadlineLineItemAction: typeof BreakdownActions.addHeadlineLineItemAction;
  removeHeadlineLineItemAction: typeof BreakdownActions.removeHeadlineLineItemAction;
  setHeadlineLineItemBreakdownNotesAction: typeof BreakdownActions.setHeadlineLineItemBreakdownNotesAction;
  setHeadlineLineItemBreakdownMarginPercentage: typeof BreakdownActions.setHeadlineLineItemBreakdownMarginPercentageAction;
  setBreakdownHold: (
    accommodationIndex: number,
    hold?: IHeadlineLineItemBreakdownAccommodationLineItem['hold']
  ) => void;
  bookingStatus: string;
  purchaseCostReviewStatus?: EPurchaseCostReviewStatus | null;
  isEditable: boolean;
  isMarginEditable: boolean;
  isPurchaseCostEditable: boolean;
  isSR: boolean;
  isTA: boolean;
  isFinanceUser: boolean;
  isLiveRate: boolean;
  isAdmin: boolean;
  isLatestVersion: boolean;
  headlineLineItemBreakdownLoad: ENetworkRequestStatus;
  resortConfirmationNumber?: string | null;
}

const MarginDisplaySpan = styled.span`
  display: block;
  border-left: 6px solid #d5d2d1;
  margin-left: 16px;
  padding-left: 16px;
  width: 200px;
  height: 42px;
`;

const GA_FLOW_TAG = 'act-bkd';

export const HeadlineLineItemBreakdown = (props: IHeadlineLineItemBreakdownComponent) => {
  if (!props.headlineLineItemBreakdown) {
    return null;
  }
  const dispatch = useDispatch();

  const transfersSuppliers = useSelector(BreakdownSelectors.breakdownTransfersSuppliersSelector);
  const groundServicesSuppliers = useSelector(BreakdownSelectors.breakdownGroundServicesSuppliersSelector);
  const activitiesSuppliers = useSelector(BreakdownSelectors.breakdownActivitiesSuppliersSelector);

  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);

  const headlineLineItemBreakdown: IHeadlineLineItemBreakdown = props.headlineLineItemBreakdown;
  const shouldShowPurchaseCost = props.isSR || props.isFinanceUser;

  const { dynamicParameters, dynamicParametersAreEmpty } = useDynamicParameters();

  const setResortConfirmationNumberActionCallback = useCallback(
    value => {
      dispatch(HotelDetailsActions.setResortConfirmationNumberAction(value));
    },
    [HotelDetailsActions.setResortConfirmationNumberAction]
  );

  const handleAddAncillaryActivity = useCallback(() => {
    if (dynamicParameters.GOOGLE_ANALYTICS_TRACK_ID) {
      // @ts-ignore
      window.gtag('event', `${GA_FLOW_TAG}_add-item`, {});
    }
    dispatch(BreakdownActions.openAddActivityModalAction());
  }, [dispatch]);

  const handleAddAncillaryTransfer = useCallback(() => {
    dispatch(BreakdownActions.setAddAncillaryTransferModalToggleAction(true));
  }, [dispatch]);

  const handleAddAncillaryGroundService = useCallback(() => {
    dispatch(BreakdownActions.setAddAncillaryGroundServiceModalToggleAction(true));
  }, [dispatch]);

  const handleAddAccommodationButton = useCallback(() => {
    if (dynamicParameters.ENABLE_ACCOMM_MODALS_TEMP) {
      dispatch(BreakdownActions.setAddAncillaryAccommodationModalToggleAction(true));
    } else {
      props.addHeadlineLineItemAccommodationAction();
    }
  }, [props.addHeadlineLineItemAccommodationAction]);

  const handleSelectExchangCurrency = useCallback(sv => {
    const currency = sv[0];
    if (currency) {
      dispatch(getExchangeCurrencyRequestAction(sv[0] as TCurrencyCode))
    } else {
      dispatch(resetExchangeCurrencyAction())
    }
  }, [dispatch]);

  const handleRefreshCurrencyChange = useCallback(() =>
    dispatch(refreshExchangeCurrencyAction())
    , [dispatch]);

  const availableCurrenciesToExchange = useMemo(() => {
    const bookingCurrencyCode = getCurrencyBySymbol(props.bookingCurrency ?? '').code;
    return dynamicParameters.EXCHANGE_CURRENCIES_ISO_CODES ?
      dynamicParameters.EXCHANGE_CURRENCIES_ISO_CODES
        .filter(currencyCode => currencyCode !== bookingCurrencyCode && !!getCurrency(currencyCode))
        .map(currencyCode => ({ value: currencyCode, label: getCurrency(currencyCode).name }))
      : [];
  }, [dynamicParameters, props.bookingCurrency]);

  const renderTooltipContentElement = useCallback(() =>
    <div className="inv-tooltip text-xs leading-14px p-5px font-pt-sans border border-gray-10 text-left">
      Refresh exchange rate to issue new invoice with updated information
    </div>, [])
  const currentlyEditingProductData = useSelector(BreakdownSelectors.currentlyEditingLineItemSelector);
  const currentlyEditingProductIndex = useSelector(BreakdownSelectors.currentlyEditingLineItemIndexSelector);
  const exchangeCurrencyCode = useSelector(BreakdownSelectors.exchangeCurrencyCodeSelector);
  const exchangeCurrencyRate = useSelector(BreakdownSelectors.exchangeCurrencyRateSelector);
  const exchangeCurrencyRequest = useSelector(BreakdownSelectors.exchangeCurrencyRequestSelector);
  const isInternalUser = useSelector(AuthSelectors.isInternalUser);
  const showCurrencyRate = exchangeCurrencyRate && exchangeCurrencyRequest === ENetworkRequestStatus.SUCCESS;
  const showCurrencyRateError = exchangeCurrencyCode && exchangeCurrencyRequest === ENetworkRequestStatus.ERROR;
  return (
    <div className="headline-breakdown mb-8">
      <AddActivityModalContainer bookingUuid={props.bookingUuid} bookingCurrencySymbol={props.bookingCurrency} />
      <AddTransferModalContainer bookingUuid={props.bookingUuid} bookingCurrencySymbol={props.bookingCurrency} />
      <AddGroundServiceModalContainer bookingUuid={props.bookingUuid} bookingCurrencySymbol={props.bookingCurrency} />
      <AddAccommodationModal bookingUuid={props.bookingUuid} bookingCurrencySymbol={props.bookingCurrency} />

      {/* if editing, load the modal */}
      {!isNil(currentlyEditingProductData) && !isNil(topNavigationData) && !isNil(props.bookingCurrency) && (
        <EditAncillaryProductModalContainer
          currencySymbol={props.bookingCurrency}
          bookingUuid={props.bookingUuid}
          bookingCurrencySymbol={props.bookingCurrency}
          headlineLineItemBreakdown={props.headlineLineItemBreakdown}
          currentlyEditingLineItem={currentlyEditingProductData}
          currentlyEditingLineItemIndex={currentlyEditingProductIndex || 0}
          arrivalDate={topNavigationData.arrivalDate || new Date().toISOString()}
          departureDate={topNavigationData.departureDate || new Date().toISOString()}
        />
      )}

      <div className="mt-4 pt-4 pb-4 pl-4 pr-4 border border-solid border-gray-40">
        <div className="flex items-center relative">
          <p className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">Accommodations</p>
          <div className="flex items-center absolute right-0">
            <input
              placeholder={'Confirmation N.'}
              value={props.resortConfirmationNumber || ''}
              type="text"
              readOnly={!props.isEditable}
              className={classnames(
                'w-[130px] resort-confirmation-number-input focus:outline-gray-80 text-black name border text-base border-solid border-gray-40 p-2 font-pt-sans',
                {
                  'bg-gray-10': props.isEditable === false,
                }
              )}
              onChange={e => {
                setResortConfirmationNumberActionCallback(e.target.value);
              }}
            />
          </div>
        </div>
        {headlineLineItemBreakdown.Accommodation.items.length <= 0 && (
          <div className="border border-solid border-gray-40 py-6 px-5 my-5">
            <span className="block font-pt-sans text-17px leading-22px font-bold">Accommodation</span>
            <span className="block font-pt-sans text-15px leading-20px">No accommodation added</span>
          </div>
        )}
        {headlineLineItemBreakdown.Accommodation.items.map((accom, index) => {
          const isLiveRate =
            !!accom.externalBooking ||
            accom.roomRateSource === ERateSource.SUPPLIER_UNCONFIRMED ||
            accom.roomRateSource === ERateSource.SUPPLIER_CONFIRMED;
          return (
            <AccommodationBlock
              key={`Accommodation-${index}`}
              index={index}
              accom={accom}
              setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
              removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
              addHeadlineLineItemAction={props.addHeadlineLineItemAction}
              bookingCurrencySymbol={props.bookingCurrency || ''}
              setBreakdownHoldAction={props.setBreakdownHold}
              bookingStatus={props.bookingStatus}
              isEditable={props.isEditable}
              isPurchaseCostEditable={props.isPurchaseCostEditable}
              isSR={props.isSR}
              isTA={props.isTA}
              isAdmin={props.isAdmin}
              isFinanceUser={props.isFinanceUser}
              isLiveRate={isLiveRate}
              isLatestVersion={props.isLatestVersion}
            />
          );
        })}

        {(props.isSR || props.isAdmin) && props.isEditable && (
          <FluidButton
            className="add-accommodation-button"
            type="primary"
            onClick={handleAddAccommodationButton}
          >
            Add Accommodation
          </FluidButton>
        )}
      </div>

      <div className="mt-4 pt-4 pb-4 pl-4 pr-4 border border-solid border-gray-40">
        <p className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">Transfers</p>
        <GenericProductsBlock
          suppliers={transfersSuppliers}
          productType="Transfer"
          productDisplayType="Transfer"
          className="mt-4"
          lineItems={headlineLineItemBreakdown.Transfer.items}
          includeTableHeaders={true}
          setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
          removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
          subTotalCents={headlineLineItemBreakdown.Transfer.subtotalCents}
          bookingCurrencySymbol={props.bookingCurrency}
          isPurchaseCostEditable={props.isPurchaseCostEditable}
          isSR={props.isSR}
          isFinanceUser={props.isFinanceUser}
          isAdmin={props.isAdmin}
          cta={
            props.isEditable ? (
              <button
                className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                onClick={() => {
                  // OWA-7180
                  if (dynamicParameters.ENABLE_ADD_TRANSFERS_MODAL) {
                    handleAddAncillaryTransfer();
                  } else {
                    props.addHeadlineLineItemAction('Transfer.items');
                  }
                }}
              >
                Add Transfer
              </button>
            ) : (
              undefined
            )
          }
          isEditable={props.isEditable}
        />
      </div>

      <div className="mt-4 pt-4 pb-4 pl-4 pr-4 border border-solid border-gray-40">
        <p className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">Ground Services</p>
        <GenericProductsBlock
          suppliers={groundServicesSuppliers}
          productType={'Ground Service'}
          productDisplayType={'Ground Service'}
          className="mt-4"
          includeTableHeaders={true}
          lineItems={headlineLineItemBreakdown['Ground Service'].items}
          setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
          removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
          subTotalCents={headlineLineItemBreakdown['Ground Service'].subtotalCents}
          bookingCurrencySymbol={props.bookingCurrency}
          isEditable={props.isEditable}
          isPurchaseCostEditable={props.isPurchaseCostEditable}
          isSR={props.isSR}
          isFinanceUser={props.isFinanceUser}
          isAdmin={props.isAdmin}
          cta={
            props.isEditable ? (
              <button
                className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                onClick={() => {
                  if (dynamicParameters.ENABLE_ADD_GROUND_SERVICE_MODAL) {
                    handleAddAncillaryGroundService();
                  } else {
                    props.addHeadlineLineItemAction('Ground Service.items');
                  }
                }}
              >
                Add Ground Service
              </button>
            ) : (
              undefined
            )
          }
        />
      </div>

      <div className="mt-4 pt-4 pb-4 pl-4 pr-4 border border-solid border-gray-40">
        <p className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">Activities</p>
        <GenericProductsBlock
          suppliers={activitiesSuppliers}
          productType="Bespoke"
          productDisplayType="Activity"
          className="mt-4"
          includeTableHeaders={true}
          lineItems={headlineLineItemBreakdown.Bespoke.items}
          setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
          removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
          subTotalCents={headlineLineItemBreakdown.Bespoke.subtotalCents}
          bookingCurrencySymbol={props.bookingCurrency}
          isEditable={props.isEditable}
          isPurchaseCostEditable={props.isPurchaseCostEditable}
          isSR={props.isSR}
          isFinanceUser={props.isFinanceUser}
          isAdmin={props.isAdmin}
          allowNegative
          cta={
            props.isEditable ? (
              <button
                className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                onClick={() => {
                  // OWA-6783
                  if (dynamicParameters.ENABLE_ADD_ACTIVITIES_MODAL) {
                    handleAddAncillaryActivity();
                  } else {
                    props.addHeadlineLineItemAction('Bespoke.items');
                  }
                }}
              >
                Add Activity
              </button>
            ) : (
              undefined
            )
          }
        />
      </div>

      <div className="mt-4 pt-4 pb-4 pl-4 pr-4 border border-solid border-gray-40">
        <p className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">Others</p>
        <ProductsBlock
          titleJSX={<h2 className="text-17px text-left m-0">Supplements</h2>}
          productType={'Supplement'}
          allowNegative
          className="mt-4"
          includeTableHeaders={false}
          lineItems={headlineLineItemBreakdown.Supplement.items}
          setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
          removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
          subTotalCents={headlineLineItemBreakdown.Supplement.subtotalCents}
          bookingCurrencySymbol={props.bookingCurrency}
          isEditable={props.isEditable}
          isPurchaseCostEditable={props.isPurchaseCostEditable}
          isSR={props.isSR}
          isFinanceUser={props.isFinanceUser}
          isAdmin={props.isAdmin}
          cta={
            props.isEditable ? (
              <button
                className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                onClick={() => {
                  props.addHeadlineLineItemAction('Supplement.items');
                }}
              >
                Add Supplement
              </button>
            ) : (
              undefined
            )
          }
        />
        <ProductsBlock
          titleJSX={<h2 className="text-17px text-left m-0">Additional Hotel Charges</h2>}
          productType={'Fine'}
          className="mt-4"
          includeTableHeaders={false}
          lineItems={headlineLineItemBreakdown.Fine.items}
          setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
          removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
          subTotalCents={headlineLineItemBreakdown.Fine.subtotalCents}
          bookingCurrencySymbol={props.bookingCurrency}
          isEditable={props.isEditable}
          isPurchaseCostEditable={props.isPurchaseCostEditable}
          isSR={props.isSR}
          isFinanceUser={props.isFinanceUser}
          isAdmin={props.isAdmin}
          allowNegative
          cta={
            props.isEditable ? (
              <button
                className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                onClick={() => {
                  props.addHeadlineLineItemAction('Fine.items');
                }}
              >
                Add Hotel Charge
              </button>
            ) : (
              undefined
            )
          }
        />
      </div>

      {(props.isSR || props.isAdmin || props.isFinanceUser) && (
        <div className="mt-4 pt-4 pb-4 pl-4 pr-4 border border-solid border-gray-40">
          <FinanceAdjustmentProductsBlock
            titleJSX={<h2 className="text-17px text-left m-0">Finance Adjustment</h2>}
            productType={'Finance Adjustment'}
            className="mt-4"
            includeTableHeaders={false}
            lineItems={headlineLineItemBreakdown['Finance Adjustment']?.items ?? []}
            setHeadlineLineItemFieldAction={props.setHeadlineLineItemFieldAction}
            removeHeadlineLineItemAction={props.removeHeadlineLineItemAction}
            subTotalCents={headlineLineItemBreakdown.Bespoke.subtotalCents}
            bookingCurrencySymbol={props.bookingCurrency}
            isEditable={props.isEditable}
            isPurchaseCostEditable={props.isPurchaseCostEditable}
            isSR={props.isSR}
            isFinanceUser={props.isFinanceUser}
            isAdmin={props.isAdmin}
            allowNegative
            cta={
              props.isEditable ? (
                <button
                  className="add border border-solid border-brown-80 hover:bg-brown-20 bg-white cursor-pointer font-bold text-sm p-2 rounded text-brown-80 font-pt-sans"
                  onClick={() => {
                    props.addHeadlineLineItemAction('Bespoke.items');
                  }}
                >
                  Add Activity
                </button>
              ) : (
                undefined
              )
            }
          />

          {(headlineLineItemBreakdown['Finance Adjustment']?.items ?? []).length <= 0 && (
            <span className="text-14px text-gray-80">This booking has no finance adjustment rows</span>
          )}
        </div>
      )}

      <div className="mt-4 mb-4 bg-teal-20 p-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className="margin uppercase text-xs text-left text-gray-80">Margin</th>
              <th className="cost-to-client uppercase text-xs text-right text-gray-80">Total Cost To Client</th>

              {shouldShowPurchaseCost && (
                <th className="purchase-cost uppercase text-xs flex items-center justify-end text-gray-80">
                  {!!props.purchaseCostReviewStatus && (
                    <PurchaseCostReviewStatus className="text-15px mr-2" status={props.purchaseCostReviewStatus} />
                  )}
                  Purchase Cost
                </th>
              )}

              <th className="cost-to-ta uppercase text-xs text-right text-gray-80 pr-10">Cost To Tp</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="margin flex flex-row">
                  <DecimalInput
                    disabled={!props.isMarginEditable}
                    value={props.headlineLineItemBreakdown.MarginPercentage || 0}
                    className={classnames('border text-base border-solid border-gray-40 p-2 w-[90px] font-pt-sans', {
                      'bg-gray-10': props.isEditable === false,
                    })}
                    decimalPlaces={5}
                    onBlur={updatedValue => {
                      props.setHeadlineLineItemBreakdownMarginPercentage(updatedValue as number);
                    }}
                  />
                  <span className="block p-2 ml-1 text-xl">%</span>
                  <MarginDisplaySpan className="info">{`Your current margin will be ${props.bookingCurrency
                    }${formatPrice(headlineLineItemBreakdown.MarginCalculatedValueCents! / 100)}`}</MarginDisplaySpan>
                </div>
              </td>
              <td className="text-right w-1/6">
                {headlineLineItemBreakdown.TotalCostToClientCents && (
                  <span className="cost-to-client text-xl font-bold uppercase text-right">
                    {props.bookingCurrency}
                    {formatPrice(headlineLineItemBreakdown.TotalCostToClientCents / 100)}
                  </span>
                )}
              </td>
              {(props.isSR || props.isFinanceUser) && (
                <td className="text-right w-1/6">
                  <span className="cost-before-discount text-lg font-bold uppercase text-right">
                    {props.bookingCurrency}
                    {formatPrice((headlineLineItemBreakdown.PurchaseCostSubtotalCents || 0) / 100)}
                  </span>
                </td>
              )}
              <td className="text-right w-1/6 pr-10">
                {headlineLineItemBreakdown.SubtotalCents && (
                  <span className="cost-to-ta text-lg font-bold uppercase text-right">
                    {props.bookingCurrency}
                    {formatPrice(headlineLineItemBreakdown.SubtotalCents / 100)}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* Exchange Currency */}
        {isInternalUser && availableCurrenciesToExchange.length > 0 && (
          <div className='pr-10'>
            <VerticalSpace height='12px' />
            <div className='h-[0px] border border-gray-40 border-dashed' />
            <VerticalSpace height='12px' />
            <div className='flex justify-between items-end'>
              <div className='flex items-end gap-[10px]'>
                <div className='flex-1 max-w-250px'>
                  <div className="margin uppercase text-xs text-left text-gray-80 mb-[5px]">CURRENCY EXCHANGE</div>
                  <Multiselect
                    className="bg-ivory w-[220px]"
                    itemsClassname="bg-ivory"
                    itemCtaClassName="hover:bg-gray-10"
                    options={availableCurrenciesToExchange}
                    isCloseOnSelect={true}
                    hideCheckboxes={true}
                    isSingleSelectMode={true}
                    isIncludeClearButton={true}
                    onUpdate={handleSelectExchangCurrency}
                    selectedValues={exchangeCurrencyCode ? [exchangeCurrencyCode as string] : []}
                    placeholderText='Select ...'
                  />
                </div>
                {showCurrencyRate && <div className='my-[9px] text-[#413e3b] text-[13px] font-normal font-pt-sans'>1 {getCurrencyBySymbol(props.bookingCurrency ?? '').code} = {exchangeCurrencyRate} {exchangeCurrencyCode}</div>}
                {showCurrencyRate &&
                  <HidingTooltip position='bottom-right' renderTooltipContent={renderTooltipContentElement}>
                    <RefreshComponent onClick={handleRefreshCurrencyChange} className='cursor-pointer mb-[7px]' />
                  </HidingTooltip>}
                {showCurrencyRateError &&
                  <p className="my-[9px] font-pt-sans text-red-95 text-15px leading-19px  m-0 italic">
                    Something went wrong.
                  </p>}
              </div>
              <div className='currency-exchange-value my-[9px]'>
                <span className="cost-to-ta text-lg font-bold uppercase text-right">
                  {showCurrencyRate && headlineLineItemBreakdown.SubtotalCents && exchangeCurrencyCode ? `${getCurrencySymbol(exchangeCurrencyCode)}${formatPrice(headlineLineItemBreakdown.SubtotalCents / 100 * exchangeCurrencyRate)}` : <span className='tracking-[2px]'>---</span>}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
