import React from 'react';
import { IHotel, ISeasonalProductRate, ISupplementProduct } from 'services/BackendApi';
import { IBootstrapCountry } from 'store/modules/bootstrap/model';

import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';

export const SupplementRateDetails = ({
  hotel,
  supplement,
  seasonalProductRate,
  bootstrapCountries,
  onUpdateSupplementRate,
  onPatchSeasonalSupplementRate,
}: {
  hotel: IHotel;
  supplement: ISupplementProduct;
  seasonalProductRate: ISeasonalProductRate;
  bootstrapCountries: IBootstrapCountry[];
  onUpdateSupplementRate: (field: any, value: any) => void;
  onPatchSeasonalSupplementRate: () => void;
}) => {
  const allSeasons = hotel.seasons || [];

  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Select
        label="Season"
        value={seasonalProductRate.seasonUuid}
        onChange={val => {
          onUpdateSupplementRate('seasonUuid', val);
        }}
        options={allSeasons.map(season => {
          return {
            value: season.uuid,
            label: season.name,
          };
        })}
      />

      <Inputs.Checkbox
        label="Is on request"
        value={seasonalProductRate.isOnRequest || false}
        onChange={val => {
          onUpdateSupplementRate('isOnRequest', val);
        }}
      />

      <Inputs.Input
        label="Rate"
        value={seasonalProductRate.rate || ''}
        onChange={val => {
          onUpdateSupplementRate('rate', val);
        }}
      />

      <Inputs.Input
        label="Markup Amount"
        value={seasonalProductRate.markupAmount || ''}
        onChange={val => {
          onUpdateSupplementRate('markupAmount', val);
        }}
      />

      <Inputs.RadioCollection
        label="Markup Type"
        onChange={val => {
          onUpdateSupplementRate('markupType', val);
        }}
        collection={Inputs.markupTypes.map(markupType => {
          return {
            isChecked: markupType.value === seasonalProductRate.markupType,
            value: markupType.value,
            label: markupType.label,
          };
        })}
      />

      <Inputs.Input
        label="Internal description"
        value={seasonalProductRate.internalDescription || ''}
        onChange={val => {
          onUpdateSupplementRate('internalDescription', val);
        }}
      />

      <Inputs.Input
        label="Payment terms"
        value={seasonalProductRate.paymentTerms || ''}
        onChange={val => {
          onUpdateSupplementRate('paymentTerms', val);
        }}
      />

      <Inputs.Input
        label="Cancellation policy"
        value={seasonalProductRate.cancellationPolicy || ''}
        onChange={val => {
          onUpdateSupplementRate('cancellationPolicy', val);
        }}
      />

      <p>alternate rates</p>
      {supplement.options.ages.map(ageBracket => {
        const matchingAlternateRate = seasonalProductRate.alternateRates[ageBracket.name];
        return (
          <div key={ageBracket.name} className="grid grid-cols-4 gap-4">
            <p>{ageBracket.name}</p>
            <Inputs.Input
              value={matchingAlternateRate.rate || ''}
              onChange={val => {
                onUpdateSupplementRate(`alternateRates.${ageBracket.name}.rate`, val);
              }}
            />
            <Inputs.Input
              value={matchingAlternateRate.markupAmount || ''}
              onChange={val => {
                onUpdateSupplementRate(`alternateRates.${ageBracket.name}.markupAmount`, val);
              }}
            />
            <Inputs.RadioCollection
              collection={Inputs.markupTypes.map(mt => {
                return {
                  isChecked: matchingAlternateRate.markupType === mt.value,
                  value: mt.value,
                  label: mt.label,
                };
              })}
              onChange={val => {
                onUpdateSupplementRate(`alternateRates.${ageBracket.name}.markupType`, val);
              }}
            />
          </div>
        );
      })}

      <Inputs.CountryList
        selectedCountries={seasonalProductRate.countries ? seasonalProductRate.countries : []}
        label="Countries"
        bootstrapCountries={bootstrapCountries}
        onChange={val => {
          onUpdateSupplementRate('countries', val);
        }}
      />

      <FluidButton
        type="primary"
        className="w-[250px] self-end"
        onClick={() => {
          onPatchSeasonalSupplementRate();
        }}
      >
        Update Supplement Rate
      </FluidButton>
    </div>
  );
};
