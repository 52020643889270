import React from 'react';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';
import { ITransferProduct } from 'services/BackendApi';

const categoryTypes = [
  { value: 'none', label: 'None' },
  { value: 'flat', label: 'Flat' },
  { value: 'percentage', label: 'Percentage' },
];

export const Transfer = ({
  transfer,
  onUpdate,
  onPatchTransfer,
}: {
  transfer: ITransferProduct;
  onUpdate: (field: keyof any, value: any) => void;
  onPatchTransfer: () => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate('name', val);
        }}
        value={transfer.name}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          onUpdate('meta', {
            ...transfer.meta,
            description: val,
          });
        }}
        value={transfer.meta.description}
        rows={5}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.categoryTypes].map(mt => {
          return {
            isChecked: transfer.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate('category', val);
        }}
      />

      <Inputs.Checkbox
        label={'One way'}
        value={transfer.options.isOneWay}
        onChange={val => {
          onUpdate('options', {
            ...transfer.options,
            isOneWay: val,
          });
        }}
      />

      <Inputs.Input
        label={'Capacity'}
        onChange={val => {
          onUpdate('options', {
            ...transfer.options,
            capacity: parseInt(val),
          });
        }}
        value={transfer.options.capacity ? transfer.options.capacity.toString() : ''}
      />
    </div>
  );
};
