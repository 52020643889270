import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  ENetworkRequestStatus,
  IAccommodationProduct,
  IAccomodationProductOptions,
  IFineProduct,
  IGroundServiceProduct,
  IGroundServiceProductOptions,
  IHotel,
  IMealPlanProductOptions,
  IProduct,
  ISeason,
  ISupplementProduct,
  ITransferProduct,
  ITransferProductOptions,
  IUploadFileInfo,
  makeBackendApi,
} from 'services/BackendApi';
import { Link } from 'ui/Link';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { SimpleTabs } from 'ui/SimpleTabs';
import * as _ from 'lodash-es';
import { produce } from 'immer';
import { getCurrencySymbol } from 'utils';
import { ChildrenList } from './components/ChildrenList';
import { Season } from './components/Season';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';
import { MealPlan } from './components/MealPlan';
import { Room } from './components/Room';
import { AddNewRate } from './components/AddNewRate';
import { Uploads } from './components/Uploads';
import FluidButton from 'ui/FluidButton';
import { useModal } from 'hooks/useModal';
import { UploadModal } from './components/UploadModal';
import { RoomRateDetails } from './components/RoomRateDetails';
import { RoomRateAddons } from './components/RoomRateAddons';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';
import { Transfer } from './components/Transfer';
import { Supplement } from './components/Supplement';
import { GroundService } from './components/GroundService';
import { Fine } from './components/Fine';
import { TransferRateDetails } from './components/TransferRateDetails';
import { SupplementRateDetails } from './components/SupplementRateDetails';
import { GroundServiceRateDetails } from './components/GroundServiceRateDetails';
import { FineRateDetails } from './components/FineRateDetails';
import { CreateSeasonModal } from './components/CreateSeasonModal';
import { CreateRoomModal } from './components/CreateRoomModal';

export const EditChildren = () => {
  const match = useRouteMatch<{ hotelUuid: string }>();
  const hotelUuid = match.params.hotelUuid;
  const dispatch = useDispatch();
  const backendApi = makeBackendApi();

  const [hotel, setHotel] = useState<IHotel | null>(null);
  const [draftHotel, setDraftHotel] = useState<IHotel | null>(null);

  const [updatingRequest, setUpdatingRequest] = useState(ENetworkRequestStatus.IDLE);

  const uploadModalData = useModal();

  const createSeasonModalData = useModal();
  const createRoomModalData = useModal();

  const bootstrapCountries = useSelector(BootstrapSelectors.getBootstrapCountriesSelector);

  const retrieveHotel = async () => {
    setUpdatingRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .hotelAdminGetHotel(hotelUuid, [
        'accommodationProducts',
        'accommodationProducts.seasonalProductRates',
        'accommodationProducts.seasonalProductAddonRates',
        'accommodationProducts.uploads',
        'seasons',
        'seasons.seasonDates',

        'fineProducts',
        'fineProducts.seasonalProductRates',
        'fineProducts.uploads',

        'groundServiceProducts',
        'groundServiceProducts.uploads',
        'groundServiceProducts.seasonalProductRates',

        'mealPlanProducts',
        'mealPlanProducts.uploads',
        'mealPlanProducts.seasonalProductRates',

        'transferProducts',
        'transferProducts.uploads',
        'transferProducts.seasonalProductRates',

        'supplementProducts',
        'supplementProducts.uploads',
        'supplementProducts.seasonalProductRates',
      ])
      .then(res => {
        setHotel(res.data.data);
        setDraftHotel(res.data.data);
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
      });
  };

  useEffect(() => {
    retrieveHotel();
  }, [hotelUuid]);

  const onOpenChildProduct = (childItem: IProduct<any>, childListKey: string) => {
    if (!hotel || !childItem) {
      return;
    }

    // get the details of the child product
    backendApi
      .hotelAdminGetProduct(childItem.uuid, [
        'seasonalProductRates',
        'seasonalProductRates.season',
        'seasonalProductRates.product',
        'seasonalProductRates.countries',
        'seasonalProductRates.seasonalProductAddonRates',
        'seasonalProductRates.uploads',
        'uploads',
      ])
      .then(childProductResponse => {
        const childIndex = hotel[childListKey].findIndex(c => c.uuid === childItem.uuid);
        let updatedHotel = {
          ...hotel,
        };

        const { uploads, seasonalProductRates } = childProductResponse.data.data;

        // if we have uploads, put them into the child object in the updatedHotel object
        if (uploads.length >= 0) {
          updatedHotel = produce(updatedHotel, draftHotel => {
            draftHotel[childListKey][childIndex].uploads = uploads;
          });
        } else {
          updatedHotel[childListKey][childIndex].uploads = [];
        }

        // if this child doesn't have any seasonal product rates, update the hotel in state and exit out
        if (seasonalProductRates.length <= 0) {
          setHotel({
            ...updatedHotel,
          });
          setDraftHotel({
            ...updatedHotel,
          });
          return;
        }

        // otherwise, for each seasonal product rate, get the seasonalProductAddonRates
        // so for each id, make a request...
        const seasonalProductRateRequests: Promise<AxiosResponse>[] = seasonalProductRates
          .map(spr => spr.uuid)
          .map(sprUuid =>
            backendApi.hotelAdminGetSeasonalProductRate(sprUuid, [
              'seasonalProductAddonRates',
              'seasonalProductAddonRates.seasonalProductRate',
              'seasonalProductAddonRates.product',
              'seasonalProductAddonRates.uploads',
              'countries',
              'uploads',
            ])
          );

        // ...then extract the data and put it into the child object in the updatedHotel object
        Promise.all(seasonalProductRateRequests).then(seasonalProductRateResponses => {
          const parentItem = hotel[childListKey].find(c => c.uuid === childItem.uuid);
          const parentItemIndex = hotel[childListKey].findIndex(c => c.uuid === childItem.uuid);

          // maybe just use all the seasonal product rate data? not just the addons?
          updatedHotel = produce(updatedHotel, draftHotel => {
            seasonalProductRateResponses.forEach((res, index) => {
              // @ts-ignore
              const seasonalProductRateIndex = parentItem!.seasonalProductRates.findIndex(
                spr => spr.uuid === res.data.data.uuid
              );

              // @ts-ignore
              hotel[childListKey][parentItemIndex].seasonalProductRates[seasonalProductRateIndex] = res.data.data;
            });
          });

          // ...and then update the hotel in state
          setHotel({
            ...updatedHotel,
          });
          setDraftHotel({
            ...updatedHotel,
          });
        });
      });
  };

  const patchSeason = async (hotelUuid, seasonUuid, updatedData) => {
    // patch the season
    // for each of the seasonDates
    // if it has a UUID, patch it
    // if it doesnt, create it

    setUpdatingRequest(ENetworkRequestStatus.PENDING);

    // update the season
    backendApi
      .hotelAdminPatchSeason(seasonUuid, {
        ...updatedData,
        seasonDates: undefined,
      })
      .then(res => {
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);

        // then update the season dates
        const seasonDateRequests: Promise<AxiosResponse>[] = [];
        updatedData.seasonDates.forEach(sd => {
          if (sd.uuid) {
            seasonDateRequests.push(backendApi.hotelAdminPutSeasonDate(sd.uuid, sd));
          } else {
            seasonDateRequests.push(backendApi.hotelAdminPostSeasonDate(sd));
          }
        });

        Promise.all(seasonDateRequests)
          .then(responses => {
            setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
            dispatch(
              enqueueNotification({
                message: `Season "${updatedData.name}" updated successfully`,
                options: {
                  variant: 'success',
                },
              })
            );
          })
          .catch(error => {
            setUpdatingRequest(ENetworkRequestStatus.ERROR);
            dispatch(
              enqueueNotification({
                message: `Failed to update season "${updatedData.name}"`,
                options: {
                  variant: 'error',
                },
              })
            );
          });
      });
  };

  const patchMealPlan = async (hotelUuid, mealPlanUuid, mealPlanData: Partial<IProduct<IMealPlanProductOptions>>) => {
    setUpdatingRequest(ENetworkRequestStatus.PENDING);

    backendApi
      .hotelAdminPatchProduct(mealPlanUuid, mealPlanData)
      .then(res => {
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Meal Plan "${mealPlanData.name}" updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating meal plan "${mealPlanData.name}"`,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

  const patchRoom = async (roomUuid: string, room: IProduct<IAccomodationProductOptions>) => {
    setUpdatingRequest(ENetworkRequestStatus.PENDING);

    backendApi
      .hotelAdminPatchProduct(roomUuid, room)
      .then(res => {
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Room updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating room`,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

  const patchTransfer = async (transferUuid: string, transfer: ITransferProduct) => {
    setUpdatingRequest(ENetworkRequestStatus.PENDING);

    backendApi
      .hotelAdminPatchProduct(transferUuid, transfer)
      .then(res => {
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
        dispatch(
          enqueueNotification({
            message: `Transfer updated successfully`,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        console.error('error', error);
        setUpdatingRequest(ENetworkRequestStatus.ERROR);
        dispatch(
          enqueueNotification({
            message: `Error updating transfer`,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

  const patchSupplement = async (supplementUuid: string, supplement: ISupplementProduct) => {
    backendApi
      .hotelAdminPatchProduct(supplementUuid, supplement)
      .then(() => {
        dispatch(
          enqueueNotification({
            message: `Successfully updated supplement`,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          enqueueNotification({
            message: `Error updating supplement`,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

  const patchGroundService = async (groundServiceUuid: string, groundService: IGroundServiceProduct) => {
    backendApi
      .hotelAdminPatchProduct(groundServiceUuid, groundService)
      .then(() => {
        dispatch(
          enqueueNotification({
            message: `Successfully updated ground service`,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          enqueueNotification({
            message: `Error updating ground service`,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

  const patchFineProduct = async (fineUuid: string, fine: IFineProduct) => {
    backendApi
      .hotelAdminPatchProduct(fineUuid, fine)
      .then(() => {
        dispatch(
          enqueueNotification({
            message: `Successfully updated fine`,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          enqueueNotification({
            message: `Error updating fine`,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

  const patchSeasonalProductRate = async (key: string, childItemUuid: string, seasonalProductRateUuid: string) => {
    if (!draftHotel || !draftHotel[key]) {
      return;
    }
    const itemIndex = draftHotel[key].findIndex(c => c.uuid === childItemUuid);
    const item = draftHotel[key][itemIndex];
    const seasonalProductRateIndex = item.seasonalProductRates.findIndex(c => c.uuid === seasonalProductRateUuid);
    const seasonalProductRate = item.seasonalProductRates[seasonalProductRateIndex];

    backendApi
      .hotelAdminPutSeasonalProductRate(seasonalProductRateUuid, seasonalProductRate)
      .then(res => {
        if (key === 'accommodationProducts') {
          // NOW we nee to post the seasonal product addon rate for EPS
          const epsRate = (seasonalProductRate.seasonalProductAddonRates || []).find(
            rate => rate.product.name === 'Extra Person Supplement'
          );
          if (!epsRate) {
            return;
          }
          backendApi
            .hotelAdminPatchSeasonalProductAddonRate(epsRate?.uuid, epsRate)
            .then(res => {
              dispatch(
                enqueueNotification({
                  message: `Rate updated successfully`,
                  options: {
                    variant: 'success',
                  },
                })
              );
            })
            .catch(error => {
              console.error('error', error);
              dispatch(
                enqueueNotification({
                  message: `Failed to update rate`,
                  options: {
                    variant: 'error',
                  },
                })
              );
            });
        } else {
          dispatch(
            enqueueNotification({
              message: `Rate updated successfully`,
              options: {
                variant: 'success',
              },
            })
          );
        }
      })
      .catch(error => {
        console.error('error', error);
      });
  };

  const patchRoomAddonMealPlans = async (roomProductUuid: string, seasonalProductRateUuid: string) => {
    const roomIndex = draftHotel!.accommodationProducts!.findIndex(c => c.uuid === roomProductUuid);
    const room = draftHotel!.accommodationProducts![roomIndex];
    const seasonalProductRateIndex = room.seasonalProductRates.findIndex(c => c.uuid === seasonalProductRateUuid);
    const seasonalProductRate = room.seasonalProductRates[seasonalProductRateIndex];

    // now get the meal plans
    const mealPlansAddonRates = (seasonalProductRate.seasonalProductAddonRates || []).filter(
      rate => rate.product.type === 'Meal Plan'
    );

    // now do a patch for each meal plan
    const mealPlanRequests: Promise<AxiosResponse>[] = mealPlansAddonRates.map(mealPlan => {
      return backendApi.hotelAdminPatchSeasonalProductAddonRate(mealPlan.uuid, mealPlan);
    });

    Promise.all(mealPlanRequests).then(responses => {
      console.log('responses', responses);
      dispatch(
        enqueueNotification({
          message: `Meal Plans updated successfully`,
          options: { variant: 'success' },
        })
      );
    });
  };

  const deleteUpload = async (upload: IUploadFileInfo) => {
    backendApi
      .deleteUpload(upload.uuid)
      .then(res => {
        dispatch(
          enqueueNotification({
            message: `Upload deleted successfully`,
            options: { variant: 'success' },
          })
        );
      })
      .catch(error => {
        console.error('error', error);
      });
  };

  const updateSeasonalProductRateAttribute = (
    key: string,
    childItemUuid: string,
    seasonalProductRateUuid: string,
    field: any,
    val: any
  ) => {
    if (!draftHotel || !draftHotel[key]) {
      return;
    }

    const childItemIndex = draftHotel[key].findIndex(item => item.uuid === childItemUuid);

    const childItem = draftHotel[key][childItemIndex];

    const seasonalProductRateIndex = childItem.seasonalProductRates.findIndex(
      spr => spr.uuid === seasonalProductRateUuid
    );

    const updatedHotel = produce(draftHotel, draftHotel => {
      _.set(draftHotel[key][childItemIndex].seasonalProductRates[seasonalProductRateIndex], field, val);
    });

    setHotel({
      ...updatedHotel,
    });

    setDraftHotel({
      ...updatedHotel,
    });
  };

  const updateChildItemAttribute = (key: string, childItemUuid: string, field: any, val: any) => {
    if (!draftHotel || !draftHotel[key]) {
      return;
    }

    const itemIndex = draftHotel[key].findIndex(c => c.uuid === childItemUuid);

    const updatedHotel = produce(draftHotel, draftHotel => {
      _.set(draftHotel[key][itemIndex], field, val);
    });

    setHotel({
      ...updatedHotel,
    });

    setDraftHotel({
      ...updatedHotel,
    });
  };

  const postNewSeason = (seasonData: Partial<ISeason>) => {
    backendApi
      .hotelAdminPostSeason({
        ...seasonData,
        seasonDates: undefined,
      })
      .then(res => {
        setUpdatingRequest(ENetworkRequestStatus.SUCCESS);

        // then update the season dates
        const seasonDateRequests: Promise<AxiosResponse>[] = [];
        (seasonData.seasonDates || []).forEach(sd => {
          seasonDateRequests.push(
            backendApi.hotelAdminPostSeasonDate({
              ...sd,
              seasonUuid: res.data.data.uuid,
            })
          );
        });

        Promise.all(seasonDateRequests)
          .then(responses => {
            setUpdatingRequest(ENetworkRequestStatus.SUCCESS);
            dispatch(
              enqueueNotification({
                message: `Season created successfully`,
                options: {
                  variant: 'success',
                },
              })
            );
          })
          .catch(error => {
            setUpdatingRequest(ENetworkRequestStatus.ERROR);
            dispatch(
              enqueueNotification({
                message: `Failed to create season`,
                options: {
                  variant: 'error',
                },
              })
            );
          });
      });
  };

  if (!hotel || !draftHotel) {
    return (
      <div className="container w-1280px mx-auto">
        <LoadingBar />
      </div>
    );
  }

  return (
    <div className="container w-1280px mx-auto">
      <Link to="/hotel-admin">Back to Hotels</Link>
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">
        Products - <span className="text-[26px]">Hotels - Editing "{hotel.name}" Children Products</span>
      </h1>
      <Link to={`/hotel-admin/${hotel.uuid}/edit`}>Edit Hotel</Link>
      <SimpleTabs
        tabConfig={[
          {
            title: 'Seasons',
            name: 'seasons',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={hotel}
                  singularNoun="Season"
                  childList={_.orderBy(draftHotel.seasons, ['name'], ['asc']) || []}
                  onOpen={childItem => {
                    // do nothing - seasons dont need to load their own data
                  }}
                  contentRenderer={(season: ISeason) => {
                    return (
                      <Season
                        hotel={draftHotel}
                        season={season}
                        onUpdate={(field, value) => {
                          updateChildItemAttribute('seasons', season.uuid, field, value);
                        }}
                        onCta={updatedSeason => {
                          patchSeason(hotelUuid, season.uuid, updatedSeason);
                        }}
                        ctaLabel="Update Season"
                      />
                    );
                  }}
                  renderChildSubProducts={[]}
                  onCreateTrigger={async () => {
                    const data = await createSeasonModalData.openModal();
                    await postNewSeason(data);
                    await retrieveHotel();
                  }}
                />
              );
            },
          },
          {
            title: 'Rooms',
            name: 'rooms',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={hotel}
                  singularNoun="Room"
                  childList={_.orderBy(hotel.accommodationProducts, ['name'], ['asc']) || []}
                  onOpen={childItem => {
                    onOpenChildProduct(childItem, 'accommodationProducts');
                  }}
                  contentRenderer={(room: IProduct<IAccomodationProductOptions>) => {
                    return (
                      <Room
                        room={room as IAccommodationProduct}
                        onUpdate={(field, value) => {
                          updateChildItemAttribute('accommodationProducts', room.uuid, field, value);
                        }}
                        onCta={() => {
                          patchRoom(room.uuid, room);
                        }}
                      />
                    );
                  }}
                  renderChildSubProducts={[
                    {
                      title: 'Documents',
                      name: 'documents',
                      accessKey: 'uploads',
                      tabContentRenderer: (roomProduct, uploadList) => {
                        return (
                          <div className="flex flex-col space-y-2 w-full">
                            <Uploads
                              uploads={uploadList}
                              deleteUpload={async upload => {
                                try {
                                  await deleteUpload(upload);
                                  await onOpenChildProduct(roomProduct, 'accommodationProducts');
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                            <FluidButton
                              type="secondary"
                              onClick={async () => {
                                const modalResult = await uploadModalData.openModal();
                                // then upload the file
                                if (modalResult) {
                                  const formData = new FormData();
                                  formData.append('file', modalResult.file);
                                  formData.append('tag', modalResult.tag);
                                  formData.append('displayName', modalResult.name);

                                  formData.append('ownerUuid', roomProduct.uuid);
                                  formData.append('ownerType', 'Product');

                                  backendApi.uploadFile(formData).then(async res => {
                                    await onOpenChildProduct(roomProduct, 'accommodationProducts');
                                    dispatch(
                                      enqueueNotification({
                                        message: `Upload successful`,
                                        options: { variant: 'success' },
                                      })
                                    );
                                  });
                                }
                              }}
                            >
                              Add Document
                            </FluidButton>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Rates',
                      name: 'rates',
                      accessKey: 'seasonalProductRates',
                      titleRenderer: (roomProduct, seasonalProductRate) => {
                        const matchingSeason = hotel.seasons!.find(
                          season => season.uuid === seasonalProductRate.seasonUuid
                        );
                        return (
                          <span>
                            {/* <span>{roomProduct.name}</span> */}
                            <span className="">{matchingSeason!.name}</span>
                            {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                              <span className="ml-2 font-bold">
                                {getCurrencySymbol(hotel?.defaultCurrency!)}
                                {seasonalProductRate.rate}
                              </span>
                            )}
                          </span>
                        );
                      },
                      contentRenderer: (roomProduct, seasonalProductRate) => {
                        return (
                          <div>
                            <SimpleTabs
                              className="o:w-full o:pt-0 o:pb-0"
                              tabConfig={[
                                {
                                  title: 'Rate Details',
                                  name: 'rate-details',
                                  styles: 'min-w-150px py-2',
                                  renderContent: () => {
                                    return (
                                      <RoomRateDetails
                                        hotel={draftHotel}
                                        room={roomProduct}
                                        seasonalProductRate={seasonalProductRate}
                                        bootstrapCountries={bootstrapCountries}
                                        onUpdateRoomRate={(field, value) => {
                                          updateSeasonalProductRateAttribute(
                                            'accommodationProducts',
                                            roomProduct.uuid,
                                            seasonalProductRate.uuid,
                                            field,
                                            value
                                          );
                                        }}
                                        onPatchSeasonalRoomRate={() => {
                                          patchSeasonalProductRate(
                                            'accommodationProducts',
                                            roomProduct.uuid,
                                            seasonalProductRate.uuid
                                          );
                                        }}
                                      />
                                    );
                                  },
                                },
                                {
                                  title: 'Meal Plan Rates',
                                  name: 'rate-addons',
                                  styles: 'min-w-150px py-2',
                                  renderContent: () => {
                                    return (
                                      <RoomRateAddons
                                        hotel={draftHotel}
                                        seasonalProductRate={seasonalProductRate}
                                        onUpdateRoomRate={(field, value) => {
                                          updateSeasonalProductRateAttribute(
                                            'accommodationProducts',
                                            roomProduct.uuid,
                                            seasonalProductRate.uuid,
                                            field,
                                            value
                                          );
                                        }}
                                        onPatchMealPlans={() => {
                                          patchRoomAddonMealPlans(roomProduct.uuid, seasonalProductRate.uuid);
                                        }}
                                      />
                                    );
                                  },
                                },
                                {
                                  title: 'Rate Documents',
                                  name: 'rate-documents',
                                  styles: 'min-w-150px py-2',
                                  renderContent: () => {
                                    return (
                                      <div className="flex flex-col space-y-2">
                                        <span>Not implemented yet - coming soon!</span>
                                      </div>
                                    );
                                  },
                                },
                              ]}
                            />
                          </div>
                        );
                      },
                      bottomContent: mealPlanProduct => {
                        return <AddNewRate />;
                      },
                    },
                  ]}
                  onCreateTrigger={async () => {
                    const data = await createRoomModalData.openModal();
                    console.log('data', data);
                    // await postNewSeason(data);
                    // await retrieveHotel();
                  }}
                />
              );
            },
          },
          {
            title: 'Meal Plans',
            name: 'meal-plans',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={draftHotel}
                  singularNoun="Meal Plan"
                  childList={_.orderBy(draftHotel.mealPlanProducts, ['name'], ['asc']) || []}
                  onOpen={childItem => {
                    onOpenChildProduct(childItem, 'mealPlanProducts');
                  }}
                  contentRenderer={(mealPlan: IProduct<IMealPlanProductOptions>) => {
                    return (
                      <MealPlan
                        hotel={draftHotel}
                        mealPlan={mealPlan}
                        onUpdate={(field, value) => {
                          updateChildItemAttribute('mealPlanProducts', mealPlan.uuid, field, value);
                        }}
                        onPatchMealPlan={updatedMealPlan => {
                          patchMealPlan(hotelUuid, mealPlan.uuid, updatedMealPlan);
                        }}
                      />
                    );
                  }}
                  // TODO deleting upload is just a DELETE to /uploads/8c1750ad-380f-4df8-b007-43905f28b981
                  renderChildSubProducts={[
                    {
                      title: 'Documents',
                      name: 'documents',
                      accessKey: 'uploads',
                      listFilter: uploads => {
                        return true;
                      },
                      tabContentRenderer: (mealPlanProduct, uploadList) => {
                        return (
                          <div className="flex flex-col space-y-2 w-full">
                            <Uploads
                              uploads={uploadList}
                              deleteUpload={async upload => {
                                try {
                                  await deleteUpload(upload);
                                  await onOpenChildProduct(mealPlanProduct, 'mealPlanProducts');
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                            <FluidButton
                              type="secondary"
                              onClick={async () => {
                                const modalResult = await uploadModalData.openModal();
                                // then upload the file
                                if (modalResult) {
                                  const formData = new FormData();
                                  formData.append('file', modalResult.file);
                                  formData.append('tag', modalResult.tag);
                                  formData.append('displayName', modalResult.name);

                                  formData.append('ownerUuid', mealPlanProduct.uuid);
                                  formData.append('ownerType', 'Product');

                                  backendApi.uploadFile(formData).then(async res => {
                                    await onOpenChildProduct(mealPlanProduct, 'mealPlanProducts');
                                    dispatch(
                                      enqueueNotification({
                                        message: `Upload successful`,
                                        options: { variant: 'success' },
                                      })
                                    );
                                  });
                                }
                              }}
                            >
                              Add Document
                            </FluidButton>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              );
            },
          },
          {
            title: 'Transfers',
            name: 'transfers',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={hotel}
                  singularNoun="Transfer"
                  childList={_.orderBy(hotel.transferProducts, ['name'], ['asc']) || []}
                  onOpen={transfer => {
                    onOpenChildProduct(transfer, 'transferProducts');
                  }}
                  contentRenderer={(transfer: IProduct<ITransferProductOptions>) => {
                    return (
                      <Transfer
                        transfer={transfer}
                        onUpdate={(field, value) => {
                          // updateTransfer(transfer.uuid, field, value);
                        }}
                        onPatchTransfer={() => {
                          patchTransfer(transfer.uuid, transfer);
                        }}
                      />
                    );
                  }}
                  renderChildSubProducts={[
                    {
                      title: 'Documents',
                      name: 'documents',
                      accessKey: 'uploads',
                      tabContentRenderer: (transferProduct, uploadList) => {
                        return (
                          <div className="flex flex-col space-y-2 w-full">
                            <Uploads
                              uploads={uploadList}
                              deleteUpload={async upload => {
                                try {
                                  await deleteUpload(upload);
                                  await onOpenChildProduct(transferProduct, 'transferProducts');
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                            <FluidButton
                              type="secondary"
                              onClick={async () => {
                                const modalResult = await uploadModalData.openModal();
                                // then upload the file
                                if (modalResult) {
                                  const formData = new FormData();
                                  formData.append('file', modalResult.file);
                                  formData.append('tag', modalResult.tag);
                                  formData.append('displayName', modalResult.name);

                                  formData.append('ownerUuid', transferProduct.uuid);
                                  formData.append('ownerType', 'Product');

                                  backendApi.uploadFile(formData).then(async res => {
                                    await onOpenChildProduct(transferProduct, 'transferProducts');
                                    dispatch(
                                      enqueueNotification({
                                        message: `Upload successful`,
                                        options: { variant: 'success' },
                                      })
                                    );
                                  });
                                }
                              }}
                            >
                              Add Document
                            </FluidButton>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Rates',
                      name: 'rates',
                      accessKey: 'seasonalProductRates',
                      titleRenderer: (transferProduct, seasonalProductRate) => {
                        const matchingSeason = hotel.seasons!.find(
                          season => season.uuid === seasonalProductRate.seasonUuid
                        );
                        return (
                          <span>
                            <span className="">{matchingSeason!.name}</span>
                            {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                              <span className="ml-2 font-bold">
                                {getCurrencySymbol(hotel?.defaultCurrency!)}
                                {seasonalProductRate.rate}
                              </span>
                            )}
                          </span>
                        );
                      },
                      contentRenderer: (transferProduct, seasonalProductRate) => {
                        return (
                          <SimpleTabs
                            className="o:w-full o:pt-0 o:pb-0"
                            tabConfig={[
                              {
                                title: 'Rate Details',
                                name: 'rate-details',
                                styles: 'min-w-150px py-2',
                                renderContent: () => {
                                  return (
                                    <TransferRateDetails
                                      hotel={draftHotel}
                                      transfer={transferProduct}
                                      seasonalProductRate={seasonalProductRate}
                                      bootstrapCountries={bootstrapCountries}
                                      onUpdateTransferRate={(field, value) => {
                                        updateSeasonalProductRateAttribute(
                                          'transferProducts',
                                          transferProduct.uuid,
                                          seasonalProductRate.uuid,
                                          field,
                                          value
                                        );
                                      }}
                                      onPatchSeasonalTransferRate={() => {
                                        patchSeasonalProductRate(
                                          'transferProducts',
                                          transferProduct.uuid,
                                          seasonalProductRate.uuid
                                        );
                                      }}
                                    />
                                  );
                                },
                              },
                              {
                                title: 'Rate Documents',
                                name: 'rate-documents',
                                styles: 'min-w-150px py-2',
                                renderContent: () => {
                                  return (
                                    <div className="flex flex-col space-y-2">
                                      <span>Not implemented yet - coming soon!</span>
                                    </div>
                                  );
                                },
                              },
                            ]}
                          />
                        );
                      },
                      bottomContent: mealPlanProduct => {
                        return <AddNewRate />;
                      },
                    },
                  ]}
                />
              );
            },
          },
          {
            title: 'Supplements',
            name: 'supplements',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={hotel}
                  singularNoun="Supplement"
                  childList={_.orderBy(hotel.supplementProducts, ['name'], ['asc']) || []}
                  onOpen={childItem => {
                    onOpenChildProduct(childItem, 'supplementProducts');
                  }}
                  contentRenderer={(supplementProduct: IProduct<any>) => (
                    <Supplement
                      supplement={supplementProduct}
                      onUpdate={(field, value) => {
                        // updateSupplement(childItem.uuid, field, value);
                      }}
                      onPatchSupplement={() => {
                        patchSupplement(supplementProduct.uuid, supplementProduct);
                      }}
                    />
                  )}
                  renderChildSubProducts={[
                    {
                      title: 'Documents',
                      name: 'documents',
                      accessKey: 'uploads',
                      tabContentRenderer: (supplementProduct, uploadList) => {
                        return (
                          <div className="flex flex-col space-y-2 w-full">
                            <Uploads
                              uploads={uploadList}
                              deleteUpload={async upload => {
                                try {
                                  await deleteUpload(upload);
                                  await onOpenChildProduct(supplementProduct, 'supplementProducts');
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                            <FluidButton
                              type="secondary"
                              onClick={async () => {
                                const modalResult = await uploadModalData.openModal();
                                // then upload the file
                                if (modalResult) {
                                  const formData = new FormData();
                                  formData.append('file', modalResult.file);
                                  formData.append('tag', modalResult.tag);
                                  formData.append('displayName', modalResult.name);

                                  formData.append('ownerUuid', supplementProduct.uuid);
                                  formData.append('ownerType', 'Product');

                                  backendApi.uploadFile(formData).then(async res => {
                                    await onOpenChildProduct(supplementProduct, 'supplementProducts');
                                    dispatch(
                                      enqueueNotification({
                                        message: `Upload successful`,
                                        options: { variant: 'success' },
                                      })
                                    );
                                  });
                                }
                              }}
                            >
                              Add Document
                            </FluidButton>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Rates',
                      name: 'rates',
                      accessKey: 'seasonalProductRates',
                      titleRenderer: (transferProduct, seasonalProductRate) => {
                        const matchingSeason = hotel.seasons!.find(
                          season => season.uuid === seasonalProductRate.seasonUuid
                        );
                        return (
                          <span>
                            <span className="">{matchingSeason!.name}</span>
                            {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                              <span className="ml-2 font-bold">
                                {getCurrencySymbol(hotel?.defaultCurrency!)}
                                {seasonalProductRate.rate}
                              </span>
                            )}
                          </span>
                        );
                      },
                      contentRenderer: (supplementProduct, seasonalProductRate) => {
                        return (
                          <SimpleTabs
                            className="o:w-full o:pt-0 o:pb-0"
                            tabConfig={[
                              {
                                title: 'Rate Details',
                                name: 'rate-details',
                                styles: 'min-w-150px py-2',
                                renderContent: () => {
                                  return (
                                    <SupplementRateDetails
                                      hotel={draftHotel}
                                      supplement={supplementProduct}
                                      seasonalProductRate={seasonalProductRate}
                                      bootstrapCountries={bootstrapCountries}
                                      onUpdateSupplementRate={(field, value) => {
                                        updateSeasonalProductRateAttribute(
                                          'supplementProducts',
                                          supplementProduct.uuid,
                                          seasonalProductRate.uuid,
                                          field,
                                          value
                                        );
                                      }}
                                      onPatchSeasonalSupplementRate={() => {
                                        patchSeasonalProductRate(
                                          'supplementProducts',
                                          supplementProduct.uuid,
                                          seasonalProductRate.uuid
                                        );
                                      }}
                                    />
                                  );
                                },
                              },
                              {
                                title: 'Rate Documents',
                                name: 'rate-documents',
                                styles: 'min-w-150px py-2',
                                renderContent: () => {
                                  return (
                                    <div className="flex flex-col space-y-2">
                                      <span>Not implemented yet - coming soon!</span>
                                    </div>
                                  );
                                },
                              },
                            ]}
                          />
                        );
                      },
                      bottomContent: mealPlanProduct => {
                        return <AddNewRate />;
                      },
                    },
                  ]}
                />
              );
            },
          },
          {
            title: 'Ground Services',
            name: 'ground-services',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={hotel}
                  singularNoun="Ground Service"
                  childList={_.orderBy(hotel.groundServiceProducts, ['name'], ['asc']) || []}
                  onOpen={childItem => {
                    onOpenChildProduct(childItem, 'groundServiceProducts');
                  }}
                  contentRenderer={(groundServiceProduct: IProduct<any>) => (
                    <GroundService
                      groundService={groundServiceProduct}
                      onUpdate={(field, value) => {
                        updateChildItemAttribute('groundServiceProducts', groundServiceProduct.uuid, field, value);
                      }}
                      onPatchGroundService={() => {
                        patchGroundService(groundServiceProduct.uuid, groundServiceProduct);
                      }}
                    />
                  )}
                  renderChildSubProducts={[
                    {
                      title: 'Documents',
                      name: 'documents',
                      accessKey: 'uploads',
                      tabContentRenderer: (groundService, uploadList) => {
                        return (
                          <div className="flex flex-col space-y-2 w-full">
                            <Uploads
                              uploads={uploadList}
                              deleteUpload={async upload => {
                                try {
                                  await deleteUpload(upload);
                                  await onOpenChildProduct(groundService, 'groundServiceProducts');
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                            <FluidButton
                              type="secondary"
                              onClick={async () => {
                                const modalResult = await uploadModalData.openModal();
                                // then upload the file
                                if (modalResult) {
                                  const formData = new FormData();
                                  formData.append('file', modalResult.file);
                                  formData.append('tag', modalResult.tag);
                                  formData.append('displayName', modalResult.name);

                                  formData.append('ownerUuid', groundService.uuid);
                                  formData.append('ownerType', 'Product');

                                  backendApi.uploadFile(formData).then(async res => {
                                    await onOpenChildProduct(groundService, 'groundServiceProducts');
                                    dispatch(
                                      enqueueNotification({
                                        message: `Upload successful`,
                                        options: { variant: 'success' },
                                      })
                                    );
                                  });
                                }
                              }}
                            >
                              Add Document
                            </FluidButton>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Rates',
                      name: 'rates',
                      accessKey: 'seasonalProductRates',
                      titleRenderer: (groundService, seasonalProductRate) => {
                        const matchingSeason = hotel.seasons!.find(
                          season => season.uuid === seasonalProductRate.seasonUuid
                        );
                        return (
                          <span>
                            <span className="">{matchingSeason!.name}</span>
                            {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                              <span className="ml-2 font-bold">
                                {getCurrencySymbol(hotel?.defaultCurrency!)}
                                {seasonalProductRate.rate}
                              </span>
                            )}
                          </span>
                        );
                      },
                      contentRenderer: (groundService, seasonalProductRate) => {
                        return (
                          <SimpleTabs
                            className="o:w-full o:pt-0 o:pb-0"
                            tabConfig={[
                              {
                                title: 'Rate Details',
                                name: 'rate-details',
                                styles: 'min-w-150px py-2',
                                renderContent: () => {
                                  return (
                                    <GroundServiceRateDetails
                                      hotel={draftHotel}
                                      groundService={groundService}
                                      seasonalProductRate={seasonalProductRate}
                                      bootstrapCountries={bootstrapCountries}
                                      onUpdateGroundServiceRate={(field, value) => {
                                        updateSeasonalProductRateAttribute(
                                          'groundServiceProducts',
                                          groundService.uuid,
                                          seasonalProductRate.uuid,
                                          field,
                                          value
                                        );
                                      }}
                                      onPatchSeasonalGroundServiceRate={() => {
                                        patchSeasonalProductRate(
                                          'groundServiceProducts',
                                          groundService.uuid,
                                          seasonalProductRate.uuid
                                        );
                                      }}
                                    />
                                  );
                                },
                              },
                              {
                                title: 'Rate Documents',
                                name: 'rate-documents',
                                styles: 'min-w-150px py-2',
                                renderContent: () => {
                                  return (
                                    <div className="flex flex-col space-y-2">
                                      <span>Not implemented yet - coming soon!</span>
                                    </div>
                                  );
                                },
                              },
                            ]}
                          />
                        );
                      },
                      bottomContent: mealPlanProduct => {
                        return <AddNewRate />;
                      },
                    },
                  ]}
                />
              );
            },
          },
          {
            title: 'Fines',
            name: 'fines',
            styles: 'min-w-150px',
            renderContent: () => {
              return (
                <ChildrenList
                  hotel={hotel}
                  singularNoun="Fine"
                  childList={_.orderBy(hotel.fineProducts, ['name'], ['asc']) || []}
                  onOpen={childItem => {
                    onOpenChildProduct(childItem, 'fineProducts');
                  }}
                  contentRenderer={(fineProduct: IProduct<any>) => (
                    <Fine
                      fine={fineProduct}
                      onUpdate={(field, value) => {
                        updateChildItemAttribute('fineProducts', fineProduct.uuid, field, value);
                      }}
                      onPatchFine={() => {
                        patchFineProduct(fineProduct.uuid, fineProduct);
                      }}
                    />
                  )}
                  renderChildSubProducts={[
                    {
                      title: 'Documents',
                      name: 'documents',
                      accessKey: 'uploads',
                      tabContentRenderer: (fineProduct, uploadList) => {
                        return (
                          <div className="flex flex-col space-y-2 w-full">
                            <Uploads
                              uploads={uploadList}
                              deleteUpload={async upload => {
                                try {
                                  await deleteUpload(upload);
                                  await onOpenChildProduct(fineProduct, 'fineProducts');
                                } catch (error) {
                                  console.error(error);
                                }
                              }}
                            />
                            <FluidButton
                              type="secondary"
                              onClick={async () => {
                                const modalResult = await uploadModalData.openModal();
                                // then upload the file
                                if (modalResult) {
                                  const formData = new FormData();
                                  formData.append('file', modalResult.file);
                                  formData.append('tag', modalResult.tag);
                                  formData.append('displayName', modalResult.name);

                                  formData.append('ownerUuid', fineProduct.uuid);
                                  formData.append('ownerType', 'Product');

                                  backendApi.uploadFile(formData).then(async res => {
                                    await onOpenChildProduct(fineProduct, 'fineProducts');
                                    dispatch(
                                      enqueueNotification({
                                        message: `Upload successful`,
                                        options: { variant: 'success' },
                                      })
                                    );
                                  });
                                }
                              }}
                            >
                              Add Document
                            </FluidButton>
                          </div>
                        );
                      },
                    },
                    {
                      title: 'Rates',
                      name: 'rates',
                      accessKey: 'seasonalProductRates',
                      titleRenderer: (fineProduct, seasonalProductRate) => {
                        const matchingSeason = hotel.seasons!.find(
                          season => season.uuid === seasonalProductRate.seasonUuid
                        );
                        return (
                          <span>
                            <span className="">{matchingSeason!.name}</span>
                            {seasonalProductRate.rate && seasonalProductRate.rate !== '' && (
                              <span className="ml-2 font-bold">
                                {getCurrencySymbol(hotel?.defaultCurrency!)}
                                {seasonalProductRate.rate}
                              </span>
                            )}
                          </span>
                        );
                      },
                      contentRenderer: (fineProduct, seasonalProductRate) => {
                        return (
                          <div>
                            <SimpleTabs
                              className="o:w-full o:pt-0 o:pb-0"
                              tabConfig={[
                                {
                                  title: 'Rate Details',
                                  name: 'rate-details',
                                  styles: 'min-w-150px py-2',
                                  renderContent: () => {
                                    return (
                                      <FineRateDetails
                                        hotel={draftHotel}
                                        fineProduct={fineProduct}
                                        seasonalProductRate={seasonalProductRate}
                                        bootstrapCountries={bootstrapCountries}
                                        onUpdateFineRate={(field, value) => {
                                          updateSeasonalProductRateAttribute(
                                            'fineProducts',
                                            fineProduct.uuid,
                                            seasonalProductRate.uuid,
                                            field,
                                            value
                                          );
                                        }}
                                        onPatchSeasonalFineRate={() => {
                                          patchSeasonalProductRate(
                                            'fineProducts',
                                            fineProduct.uuid,
                                            seasonalProductRate.uuid
                                          );
                                        }}
                                      />
                                    );
                                  },
                                },
                                {
                                  title: 'Rate Documents',
                                  name: 'rate-documents',
                                  styles: 'min-w-150px py-2',
                                  renderContent: () => {
                                    return (
                                      <div className="flex flex-col space-y-2">
                                        <span>Not implemented yet - coming soon!</span>
                                      </div>
                                    );
                                  },
                                },
                              ]}
                            />
                          </div>
                        );
                      },
                      bottomContent: mealPlanProduct => {
                        return <AddNewRate />;
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />

      {uploadModalData.isOpen && (
        <UploadModal onConfirm={uploadModalData.handleConfirm} onClose={uploadModalData.handleCancel} />
      )}

      {createSeasonModalData.isOpen && (
        <CreateSeasonModal
          hotel={hotel}
          onConfirm={createSeasonModalData.handleConfirm}
          onClose={createSeasonModalData.handleCancel}
        />
      )}

      {createRoomModalData.isOpen && (
        <CreateRoomModal
          hotel={hotel}
          onConfirm={createRoomModalData.handleConfirm}
          onClose={createRoomModalData.handleCancel}
        />
      )}
    </div>
  );
};
