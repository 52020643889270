import React from 'react';
import { ISupplementProduct } from 'services/BackendApi';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';

export const Fine = ({
  fine,
  onUpdate,
  onPatchFine,
}: {
  fine: ISupplementProduct;
  onUpdate: (field: keyof any, value: any) => void;
  onPatchFine: () => void;
}) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          onUpdate('name', val);
        }}
        value={fine.name}
      />

      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          onUpdate('meta', {
            ...fine.meta,
            description: val,
          });
        }}
        value={fine.meta.description}
        rows={5}
      />

      <Inputs.RadioCollection
        label="Category"
        collection={[...Inputs.categoryTypes].map(mt => {
          return {
            isChecked: fine.category === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          onUpdate('category', val);
        }}
      />

      <FluidButton
        type="primary"
        className="self-end w-[200px] mt-4"
        onClick={() => {
          onPatchFine();
        }}
      >
        Update Fine
      </FluidButton>
    </div>
  );
};
