import React from 'react';
import { IAccommodationProduct, IAccomodationProductOptions, IProduct } from 'services/BackendApi';
import * as Inputs from './Inputs';
import FluidButton from 'ui/FluidButton';

export interface iRoomProps {
  room: Partial<IAccommodationProduct>;
  onUpdate: (field: string, value: any) => void;
  onCta: (room: Partial<IProduct<IAccomodationProductOptions>>) => void;
  ctaLabel?: string;
}

export const Room = (props: iRoomProps) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <Inputs.Input
        label={'Name'}
        onChange={val => {
          props.onUpdate('name', val);
        }}
        value={props.room.name || ''}
      />
      <Inputs.Input
        label={'External Product ID'}
        onChange={val => {
          props.onUpdate('externalProductId', val);
        }}
        value={props.room.externalProductId || ''}
      />
      <Inputs.RadioCollection
        label="External Markup Type"
        collection={[{ value: 'none', label: 'None' }, ...Inputs.markupTypes].map(mt => {
          return {
            isChecked: props.room.externalMarkupType === mt.value,
            value: mt.value,
            label: mt.label,
          };
        })}
        onChange={val => {
          props.onUpdate('externalMarkupType', val);
        }}
      />
      <Inputs.Input
        label={'External Markup Amount'}
        onChange={val => {
          props.onUpdate('externalMarkupAmount', val);
        }}
        value={props.room.externalMarkupAmount || ''}
      />
      <Inputs.Input
        label={'Category Type'}
        onChange={val => {
          props.onUpdate('meta', {
            ...props.room.meta,
            categoryType: val,
          });
        }}
        value={props.room.meta?.categoryType || ''}
      />
      <Inputs.Checkbox
        label={'Room Always charges Standard Occupancy quantity of adult Meal Plans as minimum'}
        onChange={val => {
          props.onUpdate('options', {
            ...props.room.options,
            mealPlanToUseStandardOccupancyMinimum: val,
          });
        }}
        value={props.room.options?.mealPlanToUseStandardOccupancyMinimum || false}
      />
      <Inputs.TextArea
        label={'Description'}
        onChange={val => {
          props.onUpdate('meta.description', val);
        }}
        value={props.room.meta?.description || ''}
        rows={5}
      />

      <Inputs.Input
        label={'Additional Information'}
        onChange={val => {
          props.onUpdate('meta', {
            ...props.room.meta,
            moreInformation: val,
          });
        }}
        value={props.room.meta?.moreInformation || ''}
      />

      <Inputs.Input
        label={'Size'}
        onChange={val => {
          props.onUpdate('meta', {
            ...props.room.meta,
            size: parseInt(val),
          });
        }}
        value={props.room.meta?.size.toString() || ''}
      />

      <Inputs.CrudList
        label={'Amenities'}
        collection={props.room.meta?.amenities || []}
        className="w-full border border-solid border-gray-40 p-2"
        onChange={newList => {
          props.onUpdate('meta', {
            ...props.room.meta,
            amenities: newList,
          });
        }}
      />

      <Inputs.AgeGroups
        label={'Age Groups'}
        collection={props.room.options?.ages || []}
        onChange={newList => {
          props.onUpdate('options', {
            ...props.room.options,
            ages: newList,
          });
        }}
      />

      <Inputs.Input
        label={'Standard Occupancy'}
        onChange={val => {
          props.onUpdate('options', {
            ...props.room.options,
            occupancy: {
              ...props.room.options?.occupancy,
              standardOccupancy: parseInt(val),
            },
          });
        }}
        value={props.room.options?.occupancy.standardOccupancy?.toString() || ''}
      />
      <Inputs.Input
        label={'Maximum People'}
        onChange={val => {
          props.onUpdate('options', {
            ...props.room.options,
            occupancy: {
              ...props.room.options?.occupancy,
              maximumPeople: parseInt(val),
            },
          });
        }}
        value={props.room.options?.occupancy.maximumPeople.toString() || ''}
      />

      <Inputs.OccupancyLimits
        label={'Occupancy Limits'}
        collection={props.room.options?.occupancy.limits || []}
        onChange={newList => {
          props.onUpdate('room.options.occupancy.limits', newList);
        }}
      />

      <FluidButton
        type="primary"
        className="self-end w-[200px] mt-4"
        onClick={() => {
          props.onCta(props.room);
        }}
      >
        {props.ctaLabel || 'Update Room'}
      </FluidButton>
    </div>
  );
};
